import React from 'react';
import ThirdRowAllStrikes from './ThirdRowAllStrikes.js';

const Contracts = ({ contractData, addContract, screenWidth, expiryChange, strikesChange, priceChange, removeRow, endDate, url, currentFetchType, holdTillExpiry, greenColor }) => {

        //render ThirdRow for each item in contractData, passing in the contractData
        let contractRows = [];
    // let parent = 0;

        //for each key in the dictionary...
        
        for (let i = 0; i < contractData.length; i++) {
        //  if (contractData[i]["expired"] === false) {
                contractRows.push(
                    <ThirdRowAllStrikes
                        key={i}
                        index={i}
                        contract={contractData[i]}
                        screenWidth={screenWidth}
                        expiryChange={expiryChange}
                        strikesChange={strikesChange}
                        priceChange={priceChange}
                        removeRow={removeRow}
                        endDate={endDate}
                        url={url}
                        currentFetchType={currentFetchType}
                        holdTillExpiry={holdTillExpiry}
                        greenColor={greenColor}
                    />
                );
        // }
        }
    

    //now render the contractRows, followed by the add contract button, allow the button to call a function that adds an empty new contract to the contractData dictionary
        return (
            <>
                {contractRows}
                <div
                    style={{
                        width: Math.min(800, screenWidth),
                        font: '12px Futura, sans-serif',
                        textAlign: 'center',
                        margin: "0 auto",
                        display: (screenWidth > 800) ? 'flex' : 'block',
                    }}>
                    {(contractRows.length < 3) ? (
                        <button
                            style={{
                                backgroundColor: greenColor,
                                color: 'black', //'#8B8989',
                                border: 'none',
                                width: '100px',
                                height: '23px',
                                display: (screenWidth > 800) ? 'flex' : 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '12px',
                                cursor: 'pointer',
                                marginTop: '6px',
                                borderRadius: '3px',
                                marginBottom: '6px',
                            }}
                            className="addContractButton"
                            onClick={() => addContract()}
                        >
                        Add Contract
                        </button>
                    ) : null}
                </div>
            </>
        );
}

    

export default Contracts;