import React from 'react';
import DropdownApiCall from './DropdownApiCall.js';
import './index.css';
import NumberInput from './NumberInput.js';
import './removeRow.css';

const ThirdRowAllStrikes = ({ index, contract, screenWidth, expiryChange, strikesChange, priceChange, removeRow, endDate, url, currentFetchType, holdTillExpiry, greenColor }) => {
    //set endDate to Jan 1st 2025
    //const endDate = '2025-12-31';
    let stock = contract["stock"]
    let expirys = contract["expirys"]
    let expiry = contract["expiry"]
    let futurePrice = contract["futurePrice"]
    let side = contract["side"]
    let chosenStock = contract["chosenStock"]
    //console.log(index)
    //console.log(holding)
    //console.log(allStrikes)
    //if holding and allStrikes are true...
    return (
    <div 
        style={{
        width: Math.min(800, screenWidth),
        font: '12px Futura, sans-serif',
        textAlign: 'center',
        margin: "0 auto",
        display: (screenWidth > 800) ? 'flex' : 'block',
        color: '#23238E',
        }}
    >
        <DropdownApiCall
        isSearchable
        placeHolder="--expiry--"
        options={expirys}
        onChange={expiryChange}
        dataChange={strikesChange}
        url={url}
        queryParam={stock + '/' + expiry}
        initialValue={{ value: expiry, label: expiry}}
        parent={index}
        endDate={endDate}
        typeOfDropdown={'expiries'}
        currentFetchType={currentFetchType}
        titleString={"expiry"}
        />
        <NumberInput
        onChange={priceChange}
        inputPrice={futurePrice}
        side={side}
        width={screenWidth}
        parent={index}
        index={0}
        placeHolder={"Stock Price Target"}
        chosenStock={chosenStock}
        endDate={endDate}
        holdTillExpiry={holdTillExpiry}
        greenColor={greenColor}
        />
        <div className="empty-container"
        //style={{
       //     paddingLeft: '10px',
      //  }}
        />
        <div
            style={{
                width: (screenWidth > 800) ? '31px' : screenWidth,
            }}
            >
            <button
            style={{
                backgroundColor: '#1E90FF',
                color: 'black',
                border: 'none',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: (screenWidth > 800) ? 'flex' : 'center',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                cursor: 'pointer',
                marginTop: '6px',
                marginLeft: '5px',
                marginBottom: '6px',
            }}
            onClick={() => removeRow(index)}
            >x
        </button>
        </div>
    </div>
    );
};


export default ThirdRowAllStrikes;
