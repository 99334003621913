import React from 'react';
import Collapsible from './Collapsible.js';
import portfolio_input_example from './portfolio_input_example.png';
import portfolio_nums_example from './portfolio_nums_example.png';
import portfolio_graph_example from './portfolio_graph_example.png';
import portfolio_inputs_phone from './portfolio_inputs_phone.png';
import portfolio_graph_phone from './portfolio_graph_phone.png';
import compare_option_inputs_1 from './compare_option_inputs_1.png';
import compare_option_inputs_2 from './compare_option_inputs_2.png';
import compare_option_graph_1 from './compare_option_graph_1.png';
import compare_option_graph_2 from './compare_option_graph_2.png';
import compare_option_inputs_phone1 from './compare_option_inputs_phone1.png';
import compare_option_inputs_phone2 from './compare_option_inputs_phone2.png';
import compare_option_graph_phone1 from './compare_option_graph_phone1.png';
import compare_option_graph_phone2 from './compare_option_graph_phone2.png';

/*style={{
    textAlign: (this.state.width > 800) ? null : 'center',
    margin: (this.state.width > 800) ? '20px' : null,
    paddingBottom: '50px',
    font: '18px "Century Gothic", Futura, sans-serif',
  }}*/

//function UserGuide will return the collapsible elements based on a width parameter
const UserGuide = (props) => {
  
    return (
      <>
        <Collapsible
            title={"Option Pricing Methodology"}
            content={<div>
            <p>In the "Portfolio Analysis" section, for pricing the options, we use the Bjerksund Stensland version of the Black and Scholes</p>
            <p>For the inputs to the formula;</p>
            <p>Dividends are not currently taken into account (assumed to be zero)</p>
            <p>Volatility is solved for using the modified Black and Scholes with the current option price</p>
            <p>Risk rate is derived using US Treasury yields</p>
            </div>}
        />
        <Collapsible
            title={"Portfolio Analysis example"}
            content={<div>
            <p>The purpose of this feature is to estimate the value of your portfolio given future stock price possibilities</p>
            <p>Example parameters for a bull call spread (with expiry '2024-01-19') are shown below</p>
            <p>As shown in the inputs we are valuing the positions at date 2024-01-10 between stock prices 120 and 320, with 220 being an example stock price guess</p>
            <img src={(props.width > 800) ? portfolio_input_example : portfolio_inputs_phone } alt="logo" style={{width: (props.width > 800) ? '700px': '240px', height: 'auto'}} />
            <p>Max, min and expected portfolio value is shown below the inputs</p>
            <img src={portfolio_nums_example} alt="logo" style={{width: '230px', height: 'auto'}} />
            <p>The graph below charts the value of the portfolio between the max and min prices of the first stock selected</p>
            <img src={(props.width > 800) ? portfolio_graph_example : portfolio_graph_phone } alt="logo" style={{width: (props.width > 800) ? '600px' : '250px', height: 'auto'}} />
            </div>}
        />
        <Collapsible
            title={"Compare Options example"}
            content={<div>
            <p>The purpose of this feature is, given your expected future stock price, to compare the returns of all strikes with a given expiration at the same time</p>
            <p>Example parameters are shown below to see the return of each strike at expiry '2025-01-17' if the Tesla stock price reaches 420</p>
            <img src={(props.width > 800) ? compare_option_inputs_1 : compare_option_inputs_phone1 } alt="logo" style={{width: (props.width > 800) ? '700px': '240px', height: 'auto'}} />
            <p>And the corresponding graph</p>
            <img src={(props.width > 800) ? compare_option_graph_1 : compare_option_graph_phone1 } alt="logo" style={{width: (props.width > 800) ? '600px' : '250px', height: 'auto'}} />
            <p>A second example usage is to compare different expiries against each other</p>
            <p>Note also that in the first example, we held till expiry, while now we are valuing each expiry at date 2024-11-22</p>
            <img src={(props.width > 800) ? compare_option_inputs_2 : compare_option_inputs_phone2 } alt="logo" style={{width: (props.width > 800) ? '700px': '240px', height: 'auto'}} />
            <p>And the corresponding graph</p>
            <img src={(props.width > 800) ? compare_option_graph_2 : compare_option_graph_phone2 } alt="logo" style={{width: (props.width > 800) ? '600px' : '250px', height: 'auto'}} />
            </div>} 
        />
        <div className="accordion-item">
            <div className="accordion-title">
                <h3>Contact Us</h3>
            </div>
            <div className="accordion-content">
                <span>Please contact this email if you have any feature suggestions, stocks of interest that are not currently supported, or issues of any kind </span><a href = "mailto: optionsalphafinder@gmail.com">optionsalphafinder@gmail.com</a>
            </div>
        </div>
      </>
    );
}

export default UserGuide;

/*
 <Collapsible
            title={"Suggestions on how to use this Tool"}
            content={<div>
            <p>This is meant as a time-saving tool to avoid calculating the return of each strike manually. It also gives a visualisation of the risk/reward profile of a given strike</p>
            <p>Take for example the situation below, the 175 strike in green and the 290 strike in red have the same potential return but the 175 strike clearly has much lower associated risk</p>
            <p>Similarly you may decide to avoid choosing the option with the maximum possible return for an option with similar returns with lower risk</p>
            <img src={riskRewardImage} alt="logo" />
            </div>}
        />
        */
