import React from 'react';
import './index.css';
import MySwitch from './SwitchToggle.js';
import DateSelector from './DateSelector.js';

const FirstRow = ({ screenWidth, analysisChange, handleDateChange, allStrikes, chosenExpiries, displayDate, greenColor }) => {

  // if teh list chosenExpiries has dates inside, pick the min date and set it as the endDate
  // otherwise, set endDate to Dec 31st 2025
  let endDate = '2025-12-31';
  if (chosenExpiries.length > 0) {
    endDate = chosenExpiries[0];
    for (let i = 1; i < chosenExpiries.length; i++) {
      if (chosenExpiries[i] < endDate) {
        endDate = chosenExpiries[i];
      }
    }
  }

  if (allStrikes) {
    return (
      <div 
        style={{
          width: Math.min(800, screenWidth),
          font: '12px Futura, sans-serif',
          textAlign: 'center',
          margin: "0 auto",
          display: (screenWidth > 800) ? 'flex' : 'block',
          color: '#7EFE00',
          marginBottom: '20px',
        }}
        > 
        <MySwitch
          option1={"Portfolio Analysis"}
          option2={"Compare Options"}
          onChange={analysisChange}
          chosenOption={!allStrikes}
          greenColor={greenColor}
        />
      </div>
    );
  } else {
    return (
      <>
      <div 
        style={{
          width: Math.min(800, screenWidth),
          font: '12px Futura, sans-serif',
          textAlign: 'center',
          margin: "0 auto",
          display: (screenWidth > 800) ? 'flex' : 'block',
          color: '#7EFE00',
          marginBottom: '20px',
        }}
        > 
        <MySwitch
          option1={"Portfolio Analysis"}
          option2={"Compare Options"}
          onChange={analysisChange}
          chosenOption={!allStrikes}
          greenColor={greenColor}
        />
      </div>
      <div 
        style={{
          width: Math.min(800, screenWidth),
          font: '12px Futura, sans-serif',
          textAlign: 'center',
          margin: "0 auto",
          display: (screenWidth > 800) ? 'flex' : 'block',
          color: '#7EFE00',
          marginBottom: '5px',
        }}
        >
        <div className="empty-container100" />
        <div
            style={{
            backgroundColor: '#161413',
            color: greenColor, //'#00E241',
            width: '60px',
            //marginLeft: '10px',
            marginRight: '4px',
            marginTop: '2px',
            height: '15px',
            display: 'inline-block',
            border: '3px solid ' + greenColor,
            padding:'8px',
            }}
            >{'Value At '}</div>
        <DateSelector
          endDate={endDate}
          onDateChange={handleDateChange}
          width={screenWidth}
          displayDate={displayDate}
          greenColor={greenColor}
        />
        <div className="empty-container100" />
      </div>
      </>
    );
  }
}

export default FirstRow;

///<div className="valueAt">
//<h3>Value At&nbsp;&nbsp;</h3>
//</div>
