import React from 'react';
import { addDays, formatISO } from 'date-fns';

const DateSelector = ({ endDate, onDateChange, width, displayDate, greenColor }) => {
  const minDate = formatISO(addDays(new Date(), 1), { representation: 'date' });
  const end = new Date(endDate);
  end.setDate(end.getDate() - 1); // Sets the date to the day before
  const maxDate = formatISO(end, { representation: 'date' }); 

  const handleDateChange = (e) => {
    onDateChange(e.target.value);
  };

  const displayDateFormatted = formatISO(new Date(displayDate), { representation: 'date' });
  //console.log("displayDate: " + displayDate);
  //console.log("displayDateFormatted: " + displayDateFormatted);

  return (
    <input
      style={{
        border: '3px solid ' + greenColor,
        color: greenColor,
        backgroundColor: 'white',
        height: '27px',
        borderRadius: '5px',
        padding: '3px',
        flex: '1',
        maxWidth: '190px',
        minWidth: (width > 800) ? '100px' : '155px',
        margin: '0px auto',
        marginTop: '1px',
      }}
      type="date"
      min={minDate}
      max={maxDate}
      onChange={handleDateChange}
      value={displayDateFormatted}
    />
  );
};

export default DateSelector;