import React, { useState } from 'react';
import Chart from "react-apexcharts";
import './chartStyle.css';

function holdAll(contract,number,stockReturn, multiContract, maxReturnsLength) {
  let typeRet = "";
  let opReturns = [];
  let colors = ['#67C8FF','#FF7F00','#7AE845']
  for (let i = 0; i < contract["returns"].length; i++) {
      opReturns.push((100 * (contract["returns"][i] - 1)).toFixed(2))
  }
  //if maxReturnsLength > contract["returns"].length, then add nulls to the end of opReturns
  let strikes = [...contract["strikes"]]
  let premiums = [...contract["premiums"]]

  let res = {
    name: "option-return" + number,
    data: opReturns.map((ret, index) => ({
      x: strikes[index], y: ret, premium: premiums[index], stockReturns: stockReturn, opReturn: ret, typeReturn: typeRet, analysis: "all-strikes", retFormat: "%", multiContract: multiContract})),
      color: colors[number]
  };
  return res
}



function prepData({
  holding,
  allStrikes,
  contractData,
  graphReturns,
  graphPrices,
  currentValue,
    }) {

  let breakEvenVal = 0;

  let contractLength = Object.keys(contractData).length;
  let res = [];

  if (holding && allStrikes) {
    let stockReturn = (100 * (contractData[0]["stockReturn"] - 1)).toFixed(2);
    let multiContract = (contractLength == 1) ? false : true;
    let maxReturnsLength = 0;
    for (let i = 0; i < contractLength; i++) {
      if (contractData[i]["returns"].length > maxReturnsLength) {
        maxReturnsLength = contractData[i]["returns"].length;
      }
    }
    for (let i = 0; i < contractLength; i++) {
        res.push(holdAll(contractData[i], i, stockReturn, multiContract, maxReturnsLength));
    }

    let opReturns = [];
    for (let i = 0; i < contractData[0]["returns"].length; i++) {
        opReturns.push((100 * (contractData[0]["returns"][i] - 1)).toFixed(2))
    }
    let strikes = [...contractData[0]["strikes"]];
    let premiums = [...contractData[0]["premiums"]];
  //    if (maxReturnsLength > contractData[0]["returns"].length) {
   //     for (let i = contractData[0]["returns"].length; i < maxReturnsLength; i++) {
   //       opReturns.push(null)
    //      strikes.push(null)
    //      premiums.push(null)
     //   }
    //  }
   // } else {
    //  for (let i = 0; i < contractData[0]["returns"].length; i++) {
    //    opReturns.push((1 - contractData[0]["returns"][i]).toFixed(2))
    //  }
   // }

    if (contractData[0]["side"] == "buying") {
      if ((contractData[0]["contrType"] == "buy call") && (contractLength == 1)) {
        res.push({
          name: "stock-returns",
          data: opReturns.map((ret, index) => ({
            x: strikes[index], y: stockReturn, premium: premiums[index], stockReturns: stockReturn, opReturn: ret, typeReturn: '', analysis: "all-strikes", retFormat: "%", multiContract: false})),
          color: '#7AE845'
        })
      }
      res.push({
        name: "break-even",
        data: opReturns.map((ret, index) => ({
            x: strikes[index], y: breakEvenVal, premium: premiums[index], stockReturns: stockReturn, opReturn: ret, typeReturn: '', analysis: "all-strikes", retFormat: "%", multiContract: multiContract})),
        color: '#DC143C'
      });
    }

  } else if (!holding && allStrikes) {
    let stockReturn = (100 * (contractData[0]["stockReturn"] - 1)).toFixed(2);
    let multiContract = (contractLength == 1) ? false : true;
    let maxReturnsLength = 0;
    for (let i = 0; i < contractLength; i++) {
      if (contractData[i]["returns"].length > maxReturnsLength) {
        maxReturnsLength = contractData[i]["returns"].length;
      }
    }
    for (let i = 0; i < contractLength; i++) {
      res.push(holdAll(contractData[i], i, stockReturn, multiContract, maxReturnsLength));
    }

    let opReturns = [];
    for (let i = 0; i < contractData[0]["returns"].length; i++) {
      opReturns.push((100 * (contractData[0]["returns"][i] - 1)).toFixed(2))
    }
    let strikes = [...contractData[0]["strikes"]]
    let premiums = [...contractData[0]["premiums"]]
    //  if (maxReturnsLength > contractData[0]["returns"].length) {
    //    for (let i = contractData[0]["returns"].length; i < maxReturnsLength; i++) {
   //       opReturns.push(null)
    //      strikes.push(null)
    //      premiums.push(null)
    //    }
      //}
    //} else {
    //  for (let i = 0; i < contractData[0]["returns"].length; i++) {
    //    opReturns.push((1 - contractData[0]["returns"][i]).toFixed(2))
     // }
   // }
    if (contractData[0]["side"] == "buying") {
      if ((contractData[0]["contrType"] == "buy call") && (contractLength == 1)) {
        res.push({
          name: "stock-returns",
          data: opReturns.map((ret, index) => ({
            x: strikes[index], y: stockReturn, premium: premiums[index], stockReturns: stockReturn, opReturn: ret, typeReturn: '', analysis: "all-strikes", retFormat: "%", multiContract: false})),
          color: '#7AE845'
        })
      }
      res.push({
        name: "break-even",
        data: opReturns.map((ret, index) => ({
            x: strikes[index], y: breakEvenVal, premium: premiums[index], stockReturns: stockReturn, opReturn: ret, typeReturn: '', analysis: "all-strikes", retFormat: "%", multiContract: multiContract})),
        color: '#DC143C'
      });
    }

  } else if (currentValue != 0){  // this is selected strikes, we are always selling early
    let opReturns = [];
    for (let i = 0; i < graphReturns.length; i++) {
      opReturns.push(parseFloat(graphReturns[i]).toFixed(2))
    }

    res.push({
      name: "portfolio-return",
      data: opReturns.map((ret, index) => ({
        // x: graphPrices[index] ? graphPrices[index].toFixed(2) : 0,
          x: parseFloat(graphPrices[index].toFixed(2)),
          y: ret,
          analysis: "portfolio",
          retFormat: "%",
          multiContract: false})),
      color: '#67C8FF'
    });

    // copy res to another variable resCopy
    //et resCopy = res.slice(0);
    //console.log(resCopy)
    //if (contractData[0]["side"] == "buying") {
    // if ((contractData[0]["contrType"] == "calls") && (contractLength == 1)) {
    //   res.push({
    //     name: "stock-returns",
      //    data: opReturns.map((ret, index) => ({
      //      x: contractData[0]["strikes"][index], y: contractData[0]["stockReturn"], premium: contractData[0]["premiums"][index], stockReturns: contractData[0]["stockReturn"], opReturn: ret, typeReturn: '', analysis: "all-strikes", retFormat: retFormat})),
      //    color: '#7AE845'
    //   })
    // }
    res.push({
      name: "break-even",
      data: opReturns.map((ret, index) => ({
          x: parseFloat(graphPrices[index]).toFixed(2),
          y: 0,
          analysis: "portfolio",
          retFormat: "%",
          multiContract: false})),
      color: '#DC143C'
    });
  } else {
    let opReturns = [];
    for (let i = 0; i < graphReturns.length; i++) {
      opReturns.push(parseFloat(graphReturns[i]).toFixed(2))
    }

    res.push({
      name: "portfolio-return",
      data: opReturns.map((ret, index) => ({
        // x: graphPrices[index] ? graphPrices[index].toFixed(2) : 0,
          x: parseFloat(graphPrices[index].toFixed(2)),
          y: ret,
          analysis: "portfolio_dollars",
          retFormat: "$",
          multiContract: false})),
      color: '#67C8FF'
    });

    res.push({
      name: "break-even",
      data: opReturns.map((ret, index) => ({
          x: parseFloat(graphPrices[index]).toFixed(2),
          y: 0,
          analysis: "portfolio_dollars",
          retFormat: "$",
          multiContract: false})),
      color: '#DC143C'
    });
  }


  return res
}

export default function MyChart({
  holding,
  allStrikes,
  width,
  contractData,
  graphReturns,
  graphPrices,
  currentValue,
}) {
  let chartStockName = '';
  if (!allStrikes) {
    chartStockName = (contractData[0][0]["stock"] !== '--Underlying Ticker--') ? (' (' + contractData[0][0]["stock"] + ')') : '';
  }
  let chartDetails = {
    options: {
      yaxis: [
        {
          min: ((currentValue == 0) || (!allStrikes)) ? undefined : -100,  // remove later for portfolio side
          decimalsInFloat: 2,
          show: false,
          labels: {
            style: {
              colors: ['#1E90FF'], // Color for y-axis labels
            }
          }
        }
      ],
      chart:{
        id: "line"
      },
      grid: {
        borderColor: '#1E90FF'  // Color for grid lines
      },
      xaxis: {
        type: 'numeric',
        title: {
          offsetY: 0,
          text: (allStrikes) ? 'strikes' : ('stock-price' + chartStockName),
          style: {
            color: '#1E90FF',
            fontSize: '13px',
          }
        },
        labels: {
          style: {
            colors: '#1E90FF', // Color for x-axis labels
          }
        }
      },
      tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        if (data.analysis == "portfolio") {
          return '<div class="toolTips"><ul>' +
          '<li><b>return</b>: ' + data.y + data.retFormat + '</li>' +
          '<li><b>stock-price</b>: ' + data.x + '</li>' +
          '</ul></div>';
        } else if (data.analysis == "portfolio_dollars") {
          return '<div class="toolTips"><ul>' +
          '<li><b>value</b>: ' + data.y + data.retFormat + '</li>' +
          '<li><b>stock-price</b>: ' + data.x + '</li>' +
          '</ul></div>';
        } else if (data.multiContract) {
          return '<div class="toolTips"><ul>' +
          '<li><b>strike</b>: ' + data.x + '</li>' +
          '<li><b>premium</b>: ' + data.premium + '</li>' +
          '<li><b>' + data.typeReturn + 'option-return</b>: ' + data.opReturn + data.retFormat + '</li>' +
          '</ul></div>';
        } else {
          return '<div class="toolTips"><ul>' +
          '<li><b>strike</b>: ' + data.x + '</li>' +
          '<li><b>premium</b>: ' + data.premium + '</li>' +
          '<li><b>' + data.typeReturn + 'option-return</b>: ' + data.opReturn + data.retFormat + '</li>' +
          '<li><b>stock-return</b>: ' + data.stockReturns + data.retFormat + '</li>' +
          '</ul></div>';
        }
        }
      }
    },
    series: prepData({
      holding,
      allStrikes,
      contractData,
      graphReturns,
      graphPrices,
      currentValue,
    }),
  };

  if (width > 600) {
    chartDetails.options.yaxis[0].title = {
      text: 'returns',
      style: {
        color: '#1E90FF',
        fontSize: '13px',
      }
    }
    chartDetails.options.yaxis[0].show = true;
  }

  return (
      <Chart
          options={chartDetails.options}
          series={chartDetails.series}
          type="line"
          width={width}
          height={ (width > 600) ? (width * 0.66) : (width) }
       />
  )
}

