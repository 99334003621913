import React from 'react';
import Dropdown from "./Dropdown";
import DropdownApiCall from './DropdownApiCall.js';
import './index.css';
import NumberInput from './NumberInput.js';

import './removeRow.css';

const ThirdRowPortfolio = ({ index, parent, contract, screenWidth, soloStrikeChange, contrChange, expiryChange, strikesChange, removeRow, PositionChange, endDate, url, currentFetchType, greenColor }) => {
    let contrTypes = [{value: "long stock" , label: "long stock" }, {value: "short stock" , label: "short stock" }, {value: "buy call" , label: "buy call" },
                        {value: "buy put" , label: "buy put" }, { value: "sell call" , label: "sell call" }, { value: "sell put" , label: "sell put" },];
    //let sides = [{ value: "buying" , label: "buying" }, { value: "selling" , label: "selling" },];
    //set endDate to Jan 1st 2025
    //const endDate = '2025-12-31';
    //let soloStrikes = apiData[contrType]['strikes'].map((strike) => ({ value: strike, label: strike }));
    let contrType = contract["contrType"]
    let soloStrikes = contract["strikes"].map((strike) => ({ value: strike, label: strike }));
    let stock = contract["stock"]
    let expirys = contract["expirys"]
    let expiry = contract["expiry"]
    let strike = contract["strike"]
    let side = contract["side"]

    let position = contract["position"]
    //let chosenStock = contract["chosenStock"]


    //if contract["contrType"] is long stock or short stock, then hide the dropdowns for strikes and expiry
    if (contrType == "long stock" || contrType == "short stock") {
        return (
            <div 
                style={{
                width: Math.min(800, screenWidth),
                font: '12px Futura, sans-serif',
                textAlign: 'center',
                margin: "0 auto",
                display: (screenWidth > 800) ? 'flex' : 'block',
                color: '#23238E',
                }}
            >
                <div className="empty-container183" />
                <Dropdown
                    placeHolder="--contract type--"
                    options={contrTypes}
                    onChange={contrChange}
                    initialValue={{ value: contrType, label: contrType}}
                    parent={parent}
                    index={index}
                    titleString={"position type"}
                    />
                <NumberInput
                    onChange={PositionChange}
                    inputPrice={position}
                    side={side}
                    width={screenWidth}
                    parent={parent}
                    index={index}
                    placeHolder={"--Num of Shares--"}
                    greenColor={greenColor}
                    />            
                <div className="empty-container250" />
                <div
                    style={{
                        width: (screenWidth > 800) ? '31px' : screenWidth,
                    }}
                    >
                <button
                    style={{
                        backgroundColor: '#1E90FF',
                        color: 'black',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        display: (screenWidth > 800) ? 'flex' : 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '18px',
                        cursor: 'pointer',
                        marginTop: '6px',
                        marginLeft: '5px',
                        marginBottom: '6px',
                    }}
                    onClick={() => removeRow(parent, index)}
                    >x
                </button>
                </div>
            </div>
            );
    } else {
        return (
            <div 
                style={{
                width: Math.min(800, screenWidth),
                font: '12px Futura, sans-serif',
                textAlign: 'center',
                margin: "0 auto",
                display: (screenWidth > 800) ? 'flex' : 'block',
                color: '#23238E',
                }}
            >
                <div className="empty-container183" />
                <Dropdown
                    placeHolder="--contract type--"
                    options={contrTypes}
                    onChange={contrChange}
                    initialValue={{ value: contrType, label: contrType}}
                    parent={parent}
                    index={index}
                    titleString={"position type"}
                    />
                <DropdownApiCall
                    isSearchable
                    placeHolder="--expiry--"
                    options={expirys}
                    onChange={expiryChange}
                    dataChange={strikesChange}
                    url={url}
                    queryParam={stock + '/' + expiry}
                    initialValue={{ value: expiry, label: expiry }}
                    parent={parent}
                    index={index}
                    endDate={endDate}
                    typeOfDropdown={'expiries'}
                    currentFetchType={currentFetchType}
                    titleString={"expiry"}
                />
                <Dropdown
                    placeHolder="--strikes(s)--"
                    options={soloStrikes}
                    onChange={soloStrikeChange}
                    initialValue={{ value: strike, label: strike}}
                    parent={parent}
                    index={index}
                    titleString={"strike"}
                    />
                <NumberInput
                    onChange={PositionChange}
                    inputPrice={position}
                    side={side}
                    width={screenWidth}
                    parent={parent}
                    index={index}
                    placeHolder={"--Num of Shares--"}
                    greenColor={greenColor}
                    />            
            
                <div
                    style={{
                        width: (screenWidth > 800) ? '31px' : screenWidth,
                    }}
                    >
                <button
                    style={{
                        backgroundColor: '#1E90FF',
                        color: 'black',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        display: (screenWidth > 800) ? 'flex' : 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '18px',
                        cursor: 'pointer',
                        marginTop: '6px',
                        marginLeft: '5px',
                    }}
                    onClick={() => removeRow(parent, index)}
                    >x
                </button>
                </div>
            </div>
            );
    }
}

 


export default ThirdRowPortfolio;
