import React, { useState } from 'react';
import { formatISO } from 'date-fns';

export default function NumberInput(props) {
  const [value, setValue] = useState(props.inputPrice);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value,props.parent,props.index);
  }
  
  let titleString;
  if (props.endDate) {
    if (props.holdTillExpiry) {
      titleString = "Your stock price estimate at expiry";
    } else {
    titleString = "Your stock price estimate at date " + formatISO(new Date(props.endDate), { representation: 'date' });
    }
  } else {
    titleString = "Number of shares";
  }
  return (
    <div title={titleString}>
      <input
        style={{
          border: '3px solid ' + props.greenColor,
          color: props.greenColor,
          backgroundColor: '#161413',
          height: '27px',
          borderRadius: '5px',
          padding: '3px',
          flex: '1',
          maxWidth: '250px',
          minWidth: (props.width > 800) ? '60px' : '240px',
          margin: '0px auto',
          marginTop: '1px',
        }}
        type="number"
        value={props.inputPrice}
        onChange={handleChange}
        placeholder={props.placeHolder}
      />
    </div>
  );
}
