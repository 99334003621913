const GBS_Limits = {
    'MAX32': 2147483248.0,
    'MIN_T': 1.0 / 1000.0,
    'MIN_X': 0.01,
    'MIN_FS': 0.01,
    'MIN_V': 0.005,
    'MAX_T': 100,
    'MAX_X': 2147483248.0,
    'MAX_FS': 2147483248.0,
    'MIN_TA': 0,
    'MIN_b': -1,
    'MIN_r': -1,
    'MAX_b': 1,
    'MAX_r': 1,
    'MAX_V': 1
}

function _test_option_type(option_type) {
    if (option_type !== "calls" && option_type !== "puts") {
        throw new Error(`Invalid Input option_type (${option_type}). Acceptable values are: calls, puts`);
    }
}

function _gbs_test_inputs(option_type, fs, x, t, r, b, v) {
    // Test inputs are reasonable
    _test_option_type(option_type);

    if (x < GBS_Limits.MIN_X || x > GBS_Limits.MAX_X || isNaN(x)) {
        throw new Error(`Invalid Input Strike Price (X). Acceptable range for inputs is ${GBS_Limits.MIN_X} to ${GBS_Limits.MAX_X}`);
    }

    if (fs < GBS_Limits.MIN_FS || fs > GBS_Limits.MAX_FS || isNaN(fs)) {
        throw new Error(`Invalid Input Forward/Spot Price (FS). Acceptable range for inputs is ${GBS_Limits.MIN_FS} to ${GBS_Limits.MAX_FS}`);
    }

    if (t < GBS_Limits.MIN_T || t > GBS_Limits.MAX_T || isNaN(t)) {
        throw new Error(`Invalid Input Time (T = ${t}). Acceptable range for inputs is ${GBS_Limits.MIN_T} to ${GBS_Limits.MAX_T}`);
    }

    if (b < GBS_Limits.MIN_b || b > GBS_Limits.MAX_b || isNaN(b)) {
        throw new Error(`Invalid Input Cost of Carry (b = ${b}). Acceptable range for inputs is ${GBS_Limits.MIN_b} to ${GBS_Limits.MAX_b}`);
    }

    if (r < GBS_Limits.MIN_r || r > GBS_Limits.MAX_r || isNaN(r)) {
        throw new Error(`Invalid Input Risk Free Rate (r = ${r}). Acceptable range for inputs is ${GBS_Limits.MIN_r} to ${GBS_Limits.MAX_r}`);
    }

    if (v < GBS_Limits.MIN_V || v > GBS_Limits.MAX_V || isNaN(v)) {
        throw new Error(`Invalid Input Implied Volatility (V = ${v}). Acceptable range for inputs is ${GBS_Limits.MIN_V} to ${GBS_Limits.MAX_V}`);
    }
}

//all code for _cbnd ///// *********  */
function normalcdf(X){
	var T=1/(1+.2316419*Math.abs(X));
	var D=.3989423*Math.exp(-X*X/2);
	var Prob=D*T*(.3193815+T*(-.3565638+T*(1.781478+T*(-1.821256+T*1.330274))));
	if (X>0) {Prob=1-Prob}
	return Prob
}   

function binormalcdf(x, y, R) {
    var s = (1 - normalcdf(x)) * (1 - normalcdf(y));
    var sqr2pi = Math.sqrt(2 * Math.PI);
    var h0 = Math.exp(-x * x / 2) / sqr2pi;
    var k0 = Math.exp(-y * y / 2) / sqr2pi;
    var h1 = -x * h0;
    var k1 = -y * k0;
    var factor = R * R / 2;
    s = s + R * h0 * k0 + factor * h1 * k1;
    var n = 2;
    var h2, k2;
    while (n * (1 - Math.abs(R)) < 5 && n < 101) {
        factor = factor * R / (n + 1);
        h2 = -x * h1 - (n - 1) * h0;
        k2 = -y * k1 - (n - 1) * k0;
        s = s + factor * h2 * k2;
        h0 = h1;
        k0 = k1;
        h1 = h2;
        k1 = k2;
        n = n + 1;
    }
    var v = 0;
    if (R > 0.95) {
        v = 1 - normalcdf(Math.max(h1, k1));
        s = v + 20 * (s - v) * (1 - R);
    } else if (R < -0.95 && h1 + k1 < 0) {
        v = Math.abs(normalcdf(h1) - normalcdf(k1));
        s = v + 20 * (s - v) * (1 + R);
    }
    return s;
}

function _cbnd(X, Y, R) {
    let M1=0;
    let M2=0;
    let S1=1;
    let S2=1;
    let Prob="NaN";
    if ((R<-1)||(R>1)){
        alert("The correlation coefficient must be between -1 and +1.");
    } else {
	    let h=-(X-M1)/S1;
	    let k=-(Y-M2)/S2;
	    Prob=binormalcdf(h,k,R);
	    Prob=Math.round(100000*Prob)/100000;
	}
    return Prob;
}
//end of _cbnd ///////////////// *********  */

/*console.log("_cbnd")
console.log(_cbnd(0, 0, 0))
console.log("expect: " + 0.25)
console.log(_cbnd(0, 0, -0.5))
console.log("expect: " +  0.16666666666666669)
console.log(_cbnd(-0.5, 0, 0))
console.log("expect: " +  0.15426876936299347)
console.log(_cbnd(0, -0.5, 0))
console.log("expect: " +  0.15426876936299347)
console.log(_cbnd(0, -0.99999999, -0.99999999))
console.log("expect: " +  0.0)
console.log(_cbnd(0.000001, -0.99999999, -0.99999999))
console.log("expect: " +  0.0)

console.log(_cbnd(0, 0, 0.5))
console.log("expect: " +  0.3333333333333333)
console.log(_cbnd(0.5, 0, 0))
console.log("expect: " +  0.3457312306370065)
console.log(_cbnd(0, 0.5, 0))
console.log("expect: " +  0.3457312306370065)
console.log(_cbnd(0, 0.99999999, 0.99999999))
console.log("expect: " +  0.5)
console.log(_cbnd(0.000001, 0.99999999, 0.99999999))
console.log("expect: " + 0.5000003989422803)*/

function _phi(fs, t, gamma, h, i, r, b, v) {
  let d1 = -(Math.log(fs / h) + (b + (gamma - 0.5) * (v ** 2)) * t) / (v * Math.sqrt(t));
  let d2 = d1 - 2 * Math.log(i / fs) / (v * Math.sqrt(t));

  let lambda1 = (-r + gamma * b + 0.5 * gamma * (gamma - 1) * (v ** 2));
  let kappa = (2 * b) / (v ** 2) + (2 * gamma - 1);

  let phi = Math.exp(lambda1 * t) * (fs ** gamma) * (normcdf(d1) - ((i / fs) ** kappa) * normcdf(d2));

  return phi;
}

/*console.log("_phi")
console.log(_phi(fs=120, t=3, gamma=4.51339343051624, h=151.696096685711, i=151.696096685711, r=.02, b=-0.03, v=0.14))
console.log("Expected: " + 1102886677.05955)
console.log(_phi(fs=125, t=3, gamma=1, h=374.061664206768, i=374.061664206768, r=.05, b=0.03, v=0.14))
console.log("Expected: " + 117.714544103477)*/

function _psi(fs, t2, gamma, h, i2, i1, t1, r, b, v) {
    let vsqrt_t1 = v * Math.sqrt(t1);
    let vsqrt_t2 = v * Math.sqrt(t2);
  
    let bgamma_t1 = (b + (gamma - 0.5) * (v ** 2)) * t1;
    let bgamma_t2 = (b + (gamma - 0.5) * (v ** 2)) * t2;
  
    let d1 = (Math.log(fs / i1) + bgamma_t1) / vsqrt_t1;
    let d3 = (Math.log(fs / i1) - bgamma_t1) / vsqrt_t1;
  
    let d2 = (Math.log((i2 ** 2) / (fs * i1)) + bgamma_t1) / vsqrt_t1;
    let d4 = (Math.log((i2 ** 2) / (fs * i1)) - bgamma_t1) / vsqrt_t1;
  
    let e1 = (Math.log(fs / h) + bgamma_t2) / vsqrt_t2;
    let e2 = (Math.log((i2 ** 2) / (fs * h)) + bgamma_t2) / vsqrt_t2;
    let e3 = (Math.log((i1 ** 2) / (fs * h)) + bgamma_t2) / vsqrt_t2;
    let e4 = (Math.log((fs * (i1 ** 2)) / (h * (i2 ** 2))) + bgamma_t2) / vsqrt_t2;
  
    let tau = Math.sqrt(t1 / t2);
    let lambda1 = (-r + gamma * b + 0.5 * gamma * (gamma - 1) * (v ** 2));
    let kappa = (2 * b) / (v ** 2) + (2 * gamma - 1);
  
    let psi = Math.exp(lambda1 * t2) * (fs ** gamma) * (_cbnd(-d1, -e1, tau)
        - ((i2 / fs) ** kappa) * _cbnd(-d2, -e2, tau)
        - ((i1 / fs) ** kappa) * _cbnd(-d3, -e3, -tau)
        + ((i1 / i2) ** kappa) * _cbnd(-d4, -e4, -tau));
    return psi;
  }

/*console.log("_psi")
console.log(_psi(fs=120, t2=3, gamma=1, h=375, i2=375, i1=300, t1=1, r=.05, b=0.03, v=0.1))
console.log("Expected: " + 112.87159814023171)
console.log(_psi(fs=125, t2=2, gamma=1, h=100, i2=100, i1=75, t1=1, r=.05, b=0.03, v=0.1))
console.log("Expected: " + 1.7805459905819128)*/

function normcdf(x) {
    let k = 1 / (1 + 0.2316419 * Math.abs(x));
    let k_sum = k * (0.319381530 + k * (-0.356563782 + k * (1.781477937 + k * (-1.821255978 + 1.330274429 * k))));
  
    if (x >= 0.0) {
        return (1.0 - (1.0 / (Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * x * x) * k_sum);
    } else {
        return 1.0 - normcdf(-x);
    }
  }
  
function normpdf(x) {
    return (1.0 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * x * x);
  }
  
function _gbs(optionType, fs, x, t, r, b, v) {
    _gbs_test_inputs(optionType, fs, x, t, r, b, v);
    let t_sqrt = Math.sqrt(t);
    let d1 = (Math.log(fs / x) + (b + (v * v) / 2) * t) / (v * t_sqrt);
    let d2 = d1 - v * t_sqrt;
  
    let value, delta, gamma, theta, vega, rho;
  
    if (optionType === "calls") {
        // it's a call
        value = fs * Math.exp((b - r) * t) * normcdf(d1) - x * Math.exp(-r * t) * normcdf(d2);
        delta = Math.exp((b - r) * t) * normcdf(d1);
        gamma = Math.exp((b - r) * t) * normpdf(d1) / (fs * v * t_sqrt);
        theta = -(fs * v * Math.exp((b - r) * t) * normpdf(d1)) / (2 * t_sqrt) - (b - r) * fs * Math.exp(
            (b - r) * t) * normcdf(d1) - r * x * Math.exp(-r * t) * normcdf(d2);
        vega = Math.exp((b - r) * t) * fs * t_sqrt * normpdf(d1);
        rho = x * t * Math.exp(-r * t) * normcdf(d2);
    } else {
        // it's a put
        value = x * Math.exp(-r * t) * normcdf(-d2) - (fs * Math.exp((b - r) * t) * normcdf(-d1));
        delta = -Math.exp((b - r) * t) * normcdf(-d1);
        gamma = Math.exp((b - r) * t) * normpdf(d1) / (fs * v * t_sqrt);
        theta = -(fs * v * Math.exp((b - r) * t) * normpdf(d1)) / (2 * t_sqrt) + (b - r) * fs * Math.exp(
            (b - r) * t) * normcdf(-d1) + r * x * Math.exp(-r * t) * normcdf(-d2);
        vega = Math.exp((b - r) * t) * fs * t_sqrt * normpdf(d1);
        rho = -x * t * Math.exp(-r * t) * normcdf(-d2);
    }
  
    return [ value, delta, gamma, theta, vega, rho ];
  }
  
  function _bjerksund_stensland_2002(fs, x, t, r, b, v) {
    const myOutput = _gbs("calls", fs, x, t, r, b, v);
  
    let e_value = myOutput[0];
    let delta = myOutput[1];
    let gamma = myOutput[2];
    let theta = myOutput[3];
    let vega = myOutput[4];
    let rho = myOutput[5];
  
    if (b >= r) {
        return [e_value, delta, gamma, theta, vega, rho];
    }
  
    let v2 = v ** 2;
    let t1 = 0.5 * (Math.sqrt(5) - 1) * t;
    let t2 = t;
  
    let beta_inside = ((b / v2 - 0.5) ** 2) + 2 * r / v2;
    beta_inside = Math.abs(beta_inside);
    let beta = (0.5 - b / v2) + Math.sqrt(beta_inside);
    let b_infinity = (beta / (beta - 1)) * x;
    let b_zero = Math.max(x, (r / (r - b)) * x);
  
    let h1 = -(b * t1 + 2 * v * Math.sqrt(t1)) * ((x ** 2) / ((b_infinity - b_zero) * b_zero));
    let h2 = -(b * t2 + 2 * v * Math.sqrt(t2)) * ((x ** 2) / ((b_infinity - b_zero) * b_zero));
  
    let i1 = b_zero + (b_infinity - b_zero) * (1 - Math.exp(h1));
    let i2 = b_zero + (b_infinity - b_zero) * (1 - Math.exp(h2));
  
    let alpha1 = (i1 - x) * (i1 ** (-beta));
    let alpha2 = (i2 - x) * (i2 ** (-beta));
  
    let value;
    if (fs >= i2) {
        value = fs - x;
    } else {
        value = (alpha2 * (fs ** beta)
                - alpha2 * _phi(fs, t1, beta, i2, i2, r, b, v)
                + _phi(fs, t1, 1, i2, i2, r, b, v)
                - _phi(fs, t1, 1, i1, i2, r, b, v)
                - x * _phi(fs, t1, 0, i2, i2, r, b, v)
                + x * _phi(fs, t1, 0, i1, i2, r, b, v)
                + alpha1 * _phi(fs, t1, beta, i1, i2, r, b, v)
                - alpha1 * _psi(fs, t2, beta, i1, i2, i1, t1, r, b, v)
                + _psi(fs, t2, 1, i1, i2, i1, t1, r, b, v)
                - _psi(fs, t2, 1, x, i2, i1, t1, r, b, v)
                - x * _psi(fs, t2, 0, i1, i2, i1, t1, r, b, v)
                + x * _psi(fs, t2, 0, x, i2, i1, t1, r, b, v));
  
          value = Math.max(value, e_value);
      }
  
      return [value, delta, gamma, theta, vega, rho];
  }

//store the time before and after to see how long it takes to run
/*let start = new Date().getTime();
console.log("Bjerksund-Stensland 2002")
console.log(_bjerksund_stensland_2002(fs=90, x=100, t=0.5, r=0.1, b=0, v=0.15))
console.log("expect: " + 0.8099)
console.log(_bjerksund_stensland_2002(fs=100, x=100, t=0.5, r=0.1, b=0, v=0.25)[0])
console.log("expect: " + 6.7661)
console.log(_bjerksund_stensland_2002(fs=110, x=100, t=0.5, r=0.1, b=0, v=0.35)[0])
console.log("expect: " + 15.5137)
console.log(_bjerksund_stensland_2002(fs=100, x=90, t=0.5, r=.1, b=0, v=0.15)[0])
console.log("expect: " + 10.5400)
console.log(_bjerksund_stensland_2002(fs=100, x=100, t=0.5, r=.1, b=0, v=0.25)[0])
console.log("expect: " + 6.7661)
console.log(_bjerksund_stensland_2002(fs=100, x=110, t=0.5, r=.1, b=0, v=0.35)[0])
console.log("expect: " + 5.8374)
let end = new Date().getTime();
console.log("time: " + (end - start) + "ms")*/

function _american_option(option_type, fs, x, t, r, b, v) {
    _gbs_test_inputs(option_type, fs, x, t, r, b, v);
    /*console.log("option_type: " + option_type)
    console.log("fs: " + fs)
    console.log("x: " + x)
    console.log("t: " + t)
    console.log("r: " + r)
    console.log("b: " + b)
    console.log("v: " + v)*/

    if (option_type === "calls") {
        return _bjerksund_stensland_2002(fs, x, t, r, b, v);
    } else {
        let put_x = fs;
        let put_fs = x;
        let put_b = -b;
        let put_r = r - b;

        return _bjerksund_stensland_2002(put_fs, put_x, t, put_r, put_b, v);
    }
}

function American(option_type, fs, x, t, r, q, v) {
    let b = r - q;
    // if fs is < 0.01, then return 0 if the option is a call, and x if the option is a put
    if (fs < 0.01) {
        if (option_type === "calls") {
            console.log("fs is less than 0.01, returning 0");
            return [0, 0, 0, 0, 0, 0];
        } else {
            return [x, 0, 0, 0, 0, 0];
        }
    }
    return _american_option(option_type, fs, x, t, r, b, v);
}

export default American;

//console.log("American Option")
/*console.log(_american_option("puts", fs=90, x=100, t=0.5, r=0.1, b=0, v=0.15)[0]);
console.log("expect: " + 10.5400);
console.log(_american_option("puts", fs=100, x=100, t=0.5, r=0.1, b=0, v=0.25)[0]);
console.log("expect: " +  6.7661);
console.log(_american_option("puts", fs=110, x=100, t=0.5, r=0.1, b=0, v=0.35)[0]);
console.log("expect: " +  5.8374);

console.log(_american_option('calls', fs=100, x=95, t=0.00273972602739726, r=0.000751040922831883, b=0, v=0.2)[0]);
console.log("expect: " +  5.0);
console.log(_american_option('calss', fs=42, x=40, t=0.75, r=0.04, b=-0.04, v=0.35)[0]);
console.log("expect: " +  5.28);
console.log(_american_option('calls', fs=90, x=100, t=0.1, r=0.10, b=0, v=0.15)[0]);
console.log("expect: " +  0.02);

    console.log("Testing that American valuation works for integer inputs")
    console.log(_american_option('calls', fs=100, x=100, t=1, r=0, b=0, v=0.35)[0])
    console.log("expect: " +  13.892)
    console.log(_american_option('puts', fs=100, x=100, t=1, r=0, b=0, v=0.35)[0])
    console.log("expect: " +  13.892)

    console.log("Testing valuation works at minimum/maximum values for T")
    console.log(_american_option('calls', 100, 100, 0.00396825396825397, 0.000771332656950173, 0, 0.15)[0])
    console.log("expect: " +  0.3769)
    console.log(_american_option('puts', 100, 100, 0.00396825396825397, 0.000771332656950173, 0, 0.15)[0])
    console.log("expect: " +  0.3769)
    console.log(_american_option('calls', 100, 100, 100, 0.042033868311581, 0, 0.15)[0])
    console.log("expect: " +  18.61206)
    console.log(_american_option('puts', 100, 100, 100, 0.042033868311581, 0, 0.15)[0])
    console.log("expect: " +  18.61206)

    console.log("Testing valuation works at minimum/maximum values for X")
    console.log(_american_option('calls', 100, 0.01, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  99.99)
    console.log(_american_option('puts', 100, 0.01, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  0)
    console.log(_american_option('calls', 100, 2147483248, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  0)
    console.log(_american_option('puts', 100, 2147483248, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  2147483148)

    console.log("Testing valuation works at minimum/maximum values for F/S")
    console.log(_american_option('calls', 0.01, 100, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  0)
    console.log(_american_option('puts', 0.01, 100, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  99.99)
    console.log(_american_option('calls', 2147483248, 100, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  2147483148)
    console.log(_american_option('puts', 2147483248, 100, 1, 0.00330252458693489, 0, 0.15)[0])
    console.log("expect: " +  0)
    console.log("Testing valuation works at minimum/maximum values for b")
    console.log(_american_option('calls', 100, 100, 1, 0, -1, 0.15)[0])
    console.log("expect: " +  0.0)
    console.log(_american_option('puts', 100, 100, 1, 0, -1, 0.15)[0])
    console.log("expect: " +  63.2121)
    console.log(_american_option('calls', 100, 100, 1, 0, 1, 0.15)[0])
    console.log("expect: " +  171.8282)
    console.log(_american_option('puts', 100, 100, 1, 0, 1, 0.15)[0])
    console.log("expect: " +  0.0)

    console.log("Testing valuation works at minimum/maximum values for r")
    console.log(_american_option('calls', 100, 100, 1, -1, 0, 0.15)[0])
    console.log("expect: " +  16.25133)
    console.log(_american_option('puts', 100, 100, 1, -1, 0, 0.15)[0])
    console.log("expect: " +  16.25133)
    console.log(_american_option('calls', 100, 100, 1, 1, 0, 0.15)[0])
    console.log("expect: " +  3.6014)
    console.log(_american_option('puts', 100, 100, 1, 1, 0, 0.15)[0])
    console.log("expect: " +  3.6014)

    console.log("Testing valuation works at minimum/maximum values for V")
    console.log(_american_option('calls', 100, 100, 1, 0.05, 0, 0.005)[0])
    console.log("expect: " +  0.1916)
    console.log(_american_option('puts', 100, 100, 1, 0.05, 0, 0.005)[0])
    console.log("expect: " +  0.1916)
    console.log(_american_option('calls', 100, 100, 1, 0.05, 0, 1)[0])
    console.log("expect: " +  36.4860)
    console.log(_american_option('puts', 100, 100, 1, 0.05, 0, 1)[0])
    console.log("expect: " +  36.4860)*/

    //console.log(_american_option('calls', 4, 50, 0.13424657534246576, 0.049, 0, 0.9492199358144666)[0])