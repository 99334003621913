import React, { useState } from 'react';
import { formatISO } from 'date-fns';

export default function MaxNumberInput(props) {
  const [value, setValue] = useState(props.inputPrice);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value,props.index,props.holding,props.allStrikes);
  }

  let titleString;
  if (props.endDate) {
    titleString = "Your maximum stock price estimate at date " + formatISO(new Date(props.endDate), { representation: 'date' });
  } else {
    titleString = "Number of shares";
  }

  if (!props.allStrikes) {
    return (
      <div
      title={titleString}
      style={{
        //flex: '1',
        maxWidth: '250px',
        minWidth: (props.width > 800) ? '100px' : '240px',
        margin: '0px auto',
      }}
      >
      <input
        style={{
          border: '3px solid ' + props.greenColor,
          color: props.greenColor,
          backgroundColor: '#161413',
          height: '27px',
          borderRadius: '5px',
          padding: '3px',
          flex: '1',
          maxWidth: '250px',
          minWidth: (props.width > 800) ? '100px' : '240px',
          margin: '0px auto',
          marginTop: '1px',
        }}
        type="number"
        value={props.inputPrice}
        onChange={handleChange}
        placeholder="Stock Price Upper Bound"
      />
      </div>
    );
  }
}

