import React from 'react';
import Dropdown from "./Dropdown";
import DropdownApiCall from './DropdownApiCall.js';
import './index.css';
import MySwitch from './SwitchToggle.js';
import DateSelector from './DateSelector.js';

const SecondRow = ({ holding, handleDateChange, contractData, screenWidth, sellEarlySwitch, contrChange, stockChangeAll, expirysChange, chosenExpiries, url, currentFetchType, displayDate, stockList, greenColor }) => {
    let contrTypes = [{value: "buy call" , label: "buy call" }, {value: "buy put" , label: "buy put" },];
    let endDate = '2025-12-31';
    if (chosenExpiries.length > 0) {
        endDate = chosenExpiries[0];
        for (let i = 1; i < chosenExpiries.length; i++) {
            if (chosenExpiries[i] < endDate) {
                endDate = chosenExpiries[i];
            }
        }
    }

    let stocks = stockList.map((tick) => ({ value: tick, label: tick }));
    let stock = contractData[0].stock;
    let contrType = contractData[0]["contrType"]
    //if holding and allStrikes are true...
    if (holding) {
      return (
        <div 
            style={{
            width: Math.min(800, screenWidth),
            font: '12px Futura, sans-serif',
            textAlign: 'center',
            margin: "0 auto",
            display: (screenWidth > 800) ? 'flex' : 'block',
            color: '#23238E',
            marginBottom: '10px',
            }}
        >
             <DropdownApiCall
                isSearchable
                placeHolder="--Underlying Ticker--"
                options={stocks}
                onChange={stockChangeAll}
                dataChange={expirysChange}
                url={url}
                queryParam={stock}
                initialValue={{ value: stock, label: stock}}
                index={0}
                typeOfDropdown={'tickers'}
                currentFetchType={currentFetchType}
                titleString={"Underlying Stock"}
            />
            <Dropdown
            placeHolder="--contract type--"
            options={contrTypes}
            onChange={contrChange}
            initialValue={{ value: contrType, label: contrType}}
            index={0}
            titleString={"contract type"}
            />
            <MySwitch
            option1={"hold till expiry"}
            option2={"sell early"}
            onChange={sellEarlySwitch}
            chosenOption={holding}
            greenColor={greenColor}
            />
        </div>
        );
    } else {
        return (
            <div 
                style={{
                width: Math.min(800, screenWidth),
                font: '12px Futura, sans-serif',
                textAlign: 'center',
                margin: "0 auto",
                display: (screenWidth > 800) ? 'flex' : 'block',
                color: '#23238E',
                marginBottom: '10px',
                }}
            >
                 <DropdownApiCall
                    isSearchable
                    placeHolder="--Underlying Ticker--"
                    options={stocks}
                    onChange={stockChangeAll}
                    dataChange={expirysChange}
                    url={url}
                    queryParam={stock}
                    initialValue={{ value: stock, label: stock}}
                    index={0}
                    typeOfDropdown={'tickers'}
                    currentFetchType={currentFetchType}
                    titleString={"Underlying Stock"}
                />
                <Dropdown
                placeHolder="--contract type--"
                options={contrTypes}
                onChange={contrChange}
                initialValue={{ value: contrType, label: contrType}}
                index={0}
                titleString={"contract type"}
                />
                <MySwitch
                option1={"hold till expiry"}
                option2={"sell early"}
                onChange={sellEarlySwitch}
                greenColor={greenColor}
                />
                <DateSelector
                endDate={endDate}
                onDateChange={handleDateChange}
                width={screenWidth}
                displayDate={displayDate}
                greenColor={greenColor}
                />
            </div>
            );
    }     
}


export default SecondRow;
