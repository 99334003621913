import American from "./BjerkStensFormula.js";

function getRiskFreeRate(riskData, mnth) {
  //riskData is a dictionary of the form {month: riskFreeRate}
  //grab the keys of riskData, put them in ascending order and find either 'mnth' or the closest month below and above 'mnth'
  let keys = Object.keys(riskData);
  keys.sort(function(a, b) {
      return a - b;
  });
  if (mnth == 0) {
      return riskData[keys[0]];
  }

  for (let i = 1; i < keys.length; i++) {
      if (keys[i] > mnth) {
          return  riskData[keys[i]];
      }
  }

  return riskData[keys[keys.length - 1]]   // if mnth is greater than all keys
}

export default function CalcReturns(strikePrices, premiums, futurePrice, contrType) {
  // Map over the strikePrices array
    // Calculate the return for this strike and futurePrice
  let res = [];
  if (contrType == "calls") {
    for (let i = 0; i < strikePrices.length; i++) {
      res.push(Math.max(0, (futurePrice - strikePrices[i]) / premiums[i]));
    };
  } else {
    for (let i = 0; i < strikePrices.length; i++) {
      res.push(Math.max(0, (strikePrices[i] - futurePrice) / premiums[i]));
    };
  };
  return res
}

export function CalcSellReturns(premiums, strikes, currentPrice, expiry) {
  //calc annualised return of selling the contracts
  //will need to decompose expiry with today's date
  const inputDate = new Date(expiry);
  const currentDate = new Date();
  const timeDiff = Math.abs(inputDate.getTime() - currentDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const powerOf = 365.25 / diffDays;
  let rets = [];
  let newStrikes = [];
  let newPremiums = [];
  for (let i = 0; i < premiums.length; i++) {
    let ret = ((currentPrice + premiums[i]) / currentPrice) ** powerOf;
    if (ret < 3) {
      rets.push(ret);
      newStrikes.push(strikes[i]);
      newPremiums.push(premiums[i]);
    }
  };
  return { returns: rets, strikes: newStrikes, premiums: newPremiums}
}

// used for calculating option prices if "Compare Options" if the setting "sell early" is selected
export function CalcEarlyReturns(apiData, futurePrice, expiry, contr, endDt) {
  let strikePrices = apiData[contr]['strikes'];
  let oldPremiums = apiData[contr]['close'];
  let volatilities = apiData[contr]['volatilities'];
  let riskRates = apiData["futureRates"];
  //console.log(endDt);
  const expDate = new Date(expiry);
  const valueDt = new Date(endDt);
  const timeDiff = Math.abs(expDate.getTime() - valueDt.getTime());
  const t = (timeDiff / (1000 * 3600 * 24)) / 365;  // yrs_till_expiry
  let mth = Math.floor(t * 12);
  let riskRate = getRiskFreeRate(riskRates, mth) / 100;
  //let v = CalculateVolatility(histPxs, expiry);
  let premiums = [];
  /*console.log(contr);
  console.log(futurePrice);
  console.log(strikePrices);
  console.log(t);
  console.log((riskRates["1Y"] / 100 ));
  console.log(0);
  console.log(v);*/  

  for (let i = 0; i < strikePrices.length; i++) {
    try {
        premiums.push(American(contr, futurePrice, strikePrices[i], t, riskRate, 0, volatilities[i])[0]);
    } catch (error) {
        console.error(`Error at index ${i}: ${error.message}`);
        premiums.push(0);
    }
  }

 // console.log(premiums);
  let res = [];
  for (let j = 0; j < strikePrices.length; j++) {
    res.push(Math.max(0, (premiums[j]) / oldPremiums[j]));
  };
  //console.log(res);
  return res

  //return CalcReturns(strikePrices, premiums, futurePrice, contr)
  // return premiums
}


// used in CalcStrikeEarlyReturns and CalcStrikeEarlyReturnsGraph, gives the future returns for a single option
function CalcStrikeEarlyReturnsData(apiData, futurePrice, expiry, contr, endDt, strike, side) {
  let oldPremium;
  let volatility;
  //for (let j = 0; j < strikes.length; j++) {
    for (let i = 0; i < apiData[contr]['strikes'].length; i++) {
      if (apiData[contr]['strikes'][i] == strike) {
        oldPremium = apiData[contr]['close'][i];
        volatility = apiData[contr]['volatilities'][i];
      }
  }
  let riskRates = apiData["futureRates"];
  //console.log(endDt);
  const expDate = new Date(expiry);
  const valueDt = new Date(endDt);
  const timeDiff = Math.abs(expDate.getTime() - valueDt.getTime());
  const t = (timeDiff / (1000 * 3600 * 24)) / 365;  // yrs_till_expiry
  let mth = Math.floor(t * 12);
  let riskRate = getRiskFreeRate(riskRates, mth) / 100;
  let premium;
  try {
      premium = (American(contr, futurePrice, strike, t, riskRate, 0, volatility)[0]);
  } catch (error) {
      console.error(`Error for strike ${strike}: ${error.message}`);
      //log the inputs to the function
      //console.log(contr);
     // console.log(futurePrice);
      //console.log(strike);
     // console.log(t);
     // console.log(volatility);
      premium = 0;
  }
  if (side == "buying") {
    return Math.max(0, premium / oldPremium)
  } else {
    return (oldPremium - premium) / oldPremium
  }
}

// used in the "Portfolio Analysis" tab, gives the future returns for all options of the underlying stock in contracts
export function CalcStrikeEarlyReturns (contracts, px, endDate) {
  for (let i = 0; i < contracts.length; i++) {
    let contract = contracts[i];
    //if strike is not set, and contr is not stock, then exit the function
    if ((contract["strike"] == "--strike--") && (contract["contr"] != 'stock')) {
      //console.log("strike not set")  // add an error pop-up later, perhaps conditional rendering, like the gpt example in "Bivariate Normal Distribution"
      // exit the for loop
      continue;
    }
    let returns;
    if ((contract["contr"] == 'stock') && (contract["side"] == 'buying')) {
     // console.log("stock buying")
     // console.log(px)
     // console.log(contracts[0]["stockPrice"])
      returns = px / contracts[0]["stockPrice"];  // need to grab stock price from api first at stock level
    //  console.log(returns)
    } else if ((contract["contr"] == 'stock') && (contract["side"] == 'selling')) {
    //  console.log("stock selling")
      returns = 2 - (px / contracts[0]["stockPrice"]);
      //next if side is buying and contr is calls or puts
    } else {
    //  console.log("stock price used " + px)
     // console.log("expiry used " + contract["expiry"])
     // console.log("contr used " + contract["contr"])
     // console.log("strike used " + contract["strike"])
     // console.log("end date " + endDate)
     // console.log("side used " + contract["side"])
      returns = CalcStrikeEarlyReturnsData(contract["apiData"], px, contract["expiry"], contract["contr"], endDate, contract["strike"], contract["side"]);
     // console.log("option price calculated")
      //let futurePosition = contract["position"] * returnsData;
    }
    contracts[i]["returns"] = returns;
  }

  return contracts;
}

export function UpdateAllReturns (contracts, endDate) {
  if (contracts[0]["futurePrice"] != "not set") {
    contracts = CalcStrikeEarlyReturns(contracts, parseFloat(contracts[0]["futurePrice"]), endDate);
  }
  if ((contracts[0]["minFuturePrice"] != "not set") && (contracts[0]["maxFuturePrice"] != "not set")) {
    contracts = CalcStrikeEarlyReturnsGraph(contracts, endDate);
  }
  return contracts;
}

// given max and min price for this stock, first calculate the individual option return graphs, then sum the price graphs up to find the total max and min return for this stock
export function CalcStrikeEarlyReturnsGraph (contracts, endDate) {
  //make an array of 50 prices from min to max
  let prices = [];
  let maxPx = parseFloat(contracts[0]["maxFuturePrice"]);
  let minPx = parseFloat(contracts[0]["minFuturePrice"]);
  //if max px less than min px, then exit the function
  if (maxPx < minPx) {
    return contracts;  // exiting early
  }
  for (let i = 0; i < 51; i++) {
    prices.push(minPx + i * ((maxPx - minPx) / 50));
  }

  //first loop through all contracts, finding the graph returns for each contract between min and max price, i.e. seperate price graph for each option
  for (let i = 0; i < contracts.length; i++) {
    let contract = contracts[i];
    //if strike is not set, and contr is not stock, then exit the function
    if ((contract["strike"] == "--strike--") && (contract["contr"] != 'stock')) {
      //console.log("strike not set")  // add an error pop-up later, perhaps conditional rendering, like the gpt example in "Bivariate Normal Distribution"
      // exit the for loop
      continue;
    }
    let returns = [];
    //console.log(prices);
    //calculate each return for each price
    for (let j = 0; j < prices.length; j++) {
      if ((contract["contr"] == 'stock') && (contract["side"] == 'buying')) {
        returns.push(prices[j] / contracts[0]["stockPrice"]);  // need to grab stock price from api first at stock level
      } else if ((contract["contr"] == 'stock') && (contract["side"] == 'selling')) {
        returns.push(2 - (prices[j] / contracts[0]["stockPrice"]));
        //next if side is buying and contr is calls or puts
      } else {
        //console.log("price in return graph: " + prices[j])
        returns.push(CalcStrikeEarlyReturnsData(contract["apiData"], prices[j], contract["expiry"], contract["contr"], endDate, contract["strike"], contract["side"]));
        //let futurePosition = contract["position"] * returnsData;
      }
    }
    contracts[i]["graphReturns"] = returns;
    contracts[i]["graphPrices"] = prices;
  }

  // now loop through all contracts, to find the max and min return for all options where this stock is the underlying, i.e add up all the price graphs of the options to find total max,min
  let graphDenominator = Array(51).fill(0);
  let graphNumerator = Array(51).fill(0);

  for (let i = 0; i < contracts.length; i++) {
    let contract = contracts[i];
    if ((contract["position"] == "--Num of Shares--" ) || ((contract["strike"] == "--strike--") && (contract["contr"] != 'stock'))) {
      //console.log("strike not set")  // add an error pop-up later, perhaps conditional rendering, like the gpt example in "Bivariate Normal Distribution"
      continue;
    }
    let position = calcPosition([[contract]], 0, 0);
    for (let k = 0; k < contract["graphReturns"].length; k++) {
      if ((contract["side"] == "selling") && (contract["contr"] != "stock")) {
        //add position * returns to numerator
        graphNumerator[k] += position * contract["graphReturns"][k];
      } else {
        graphNumerator[k] += position * contract["graphReturns"][k];
        graphDenominator[k] += position;
      }
    }
  }

  let graphReturns = [];
  for (let i = 0; i < graphNumerator.length; i++) {
   // if (graphDenominator[i] == 0) {
   //   graphReturns.push(graphNumerator[i]);
   // } else {
      graphReturns.push(((graphNumerator[i] / graphDenominator[i]) - 1) * 100);
   // }
  }

  //find the index at which the max and min returns occur
  let maxReturnIndex = graphReturns.indexOf(Math.max(...graphReturns));
  let minReturnIndex = graphReturns.indexOf(Math.min(...graphReturns));

  contracts[0]["maxReturnNumerator"] = graphNumerator[maxReturnIndex];
  contracts[0]["maxReturnDenominator"] = graphDenominator[maxReturnIndex];
  contracts[0]["minReturnNumerator"] = graphNumerator[minReturnIndex];
  contracts[0]["minReturnDenominator"] = graphDenominator[minReturnIndex];
  return contracts;
}

export function PortfolioReturns(contractData) {
  //update max return of all contracts
  let denominator = 0;
  let numerator = 0;
  let maxDenominator = 0;
  let maxNumerator = 0;
  let minDenominator = 0;
  let minNumerator = 0;
  let graphDenominator = Array(51).fill(0);
  let graphNumerator = Array(51).fill(0);
  let graphPrices = [];
  
  for (let i = 0; i < contractData.length; i++) {
    for (let j = 0; j < contractData[i].length; j++) {
      if ((contractData[i][j]["position"] == "--Num of Shares--" ) || ((contractData[i][j]["strike"] == "--strike--") && (contractData[i][j]["contr"] != 'stock'))) {
        //console.log("strike not set")  // add an error pop-up later, perhaps conditional rendering, like the gpt example in "Bivariate Normal Distribution"
        // exit the for loop
        continue;
      }
      //find either expected, min or max return by summing up (positions * returns) / positions
      let position = calcPosition(contractData, i, j);
      //if (returnType == "returns") {
        if ((contractData[i][j]["side"] == "selling") && (contractData[i][j]["contr"] != "stock")) {
          // if all contractTypes are selling, then denominator is 1
          numerator += position * contractData[i][j]["returns"];
        } else {
          numerator += position * contractData[i][j]["returns"];
          denominator += position;
        }
        //check key maxReturnNumerator is in contractData[i][j], only exists at index 0
      if (contractData[i][j].hasOwnProperty("maxReturnNumerator")) {
        maxNumerator += position * contractData[i][j]["maxReturnNumerator"];
        maxDenominator += position * contractData[i][j]["maxReturnDenominator"];
      }
      if (contractData[i][j].hasOwnProperty("minReturnNumerator")) {
        minNumerator += position * contractData[i][j]["minReturnNumerator"];
        minDenominator += position * contractData[i][j]["minReturnDenominator"];
      }

      // make graph data points
      if (contractData[i][0]["chosenStock"]) {
        //push stock prices to graphPrices
        graphPrices = contractData[i][0]["graphPrices"];
        for (let k = 0; k < contractData[i][j]["graphReturns"].length; k++) {
          if ((contractData[i][j]["side"] == "selling") && (contractData[i][j]["contr"] != "stock")) {
            //add position * returns to numerator
            graphNumerator[k] += position * contractData[i][j]["graphReturns"][k];
          } else {
            graphNumerator[k] += position * contractData[i][j]["graphReturns"][k];
            graphDenominator[k] += position;
          }
        }
      } else {
        for (let k = 0; k < contractData[i][j]["graphReturns"].length; k++) {
          if ((contractData[i][j]["side"] == "selling") && (contractData[i][j]["contr"] != "stock")) {
            //add position * returns to numerator
            graphNumerator[k] += position * contractData[i][j]["returns"];
          } else {
            graphNumerator[k] += position * contractData[i][j]["returns"];
            graphDenominator[k] += position;
          }
        }
      }
    }
  }
  let totalReturn = ((numerator / denominator) - 1) * 100;
  let maxReturn = ((maxNumerator / maxDenominator) - 1) * 100;
  let minReturn = ((minNumerator / minDenominator) - 1) * 100;

  let graphReturns = [];
  for (let i = 0; i < graphNumerator.length; i++) {
    if (graphDenominator[i] == 0) {
      graphReturns.push(graphNumerator[i]);
    } else {
      graphReturns.push(((graphNumerator[i] / graphDenominator[i]) - 1) * 100);
    }
  }
  return [totalReturn, maxReturn, minReturn, numerator, denominator, graphReturns, graphPrices];
}

export function calcPosition(contractData, i, j) {
 // if ((i == -1) && (j == -1)) {

  let position = parseFloat(contractData[i][j]["position"]);
  if (contractData[i][j]["contr"] == "stock") {
    position = position * contractData[i][0]["stockPrice"];
  } else {
    //find the index of the chosen strike in contractData[i][j]["strike"] and select the corresponding premium
    let index = contractData[i][j]["strikes"].indexOf(contractData[i][j]["strike"]);
    position = position * contractData[i][j]["premiums"][index];
  }

  return position;
}



export function CalcStrikeReturns(currentPrice, strike, origStrikes, origPremiums, minPx, futurePrice, contrType) {
 // let strikes = [];
//  for (let j = 0; j < strikesDicts.length; j++) {
 //   strikes.push(strikesDicts[j].value);
 // }

  let premium;
  //for (let j = 0; j < strikes.length; j++) {
    for (let i = 0; i < origStrikes.length; i++) {
      if (origStrikes[i] == strike) {
        premium = origPremiums[i];
      }
    }
  //}

  let res = [];
  let stockRes = [];
  let xData = [];
  for (let j = 0; j < 101; j++) {
    let thisInterval = j * futurePrice / 100;
    stockRes.push(Math.max(0, thisInterval / currentPrice));
    xData.push(thisInterval);
  }
  res.push({name: "stock", returns: stockRes, xData: xData});

  if (contrType == "calls") {
    let equilibPt = currentPrice * strike / (currentPrice - premium);
    //build up x axis data, we want to include the equilibrium point i.e. where stock return = option return and also the strike price ** but only if futurePrice is greater than them
    let xData = [];
   // let equilibPtAdded = false;
   // let strikeAdded = false;
    for (let j = 0; j < 101; j++) {
      let thisInterval = j * futurePrice / 100;
      xData.push(thisInterval);
    }
    let strikeRet = [];
    for (let j = 0; j < xData.length; j++) {
      strikeRet.push(Math.max(0, (xData[j] - strike) / premium));
    }
    res.push({name: strike, returns: strikeRet, xData: xData});
  } else {
    let xData = [];
   // let strikeAdded = false;
    for (let j = 0; j < 101; j++) {
      let thisInterval = j * futurePrice / 100;
    /*  if (thisInterval == strike) {
        strikeAdded = true;
      } else if ((thisInterval > strike) && (strikeAdded == false)) {
        xData.push(strike);
        strikeAdded = true;
      }*/
      xData.push(thisInterval);
    }
    let strikeRet = [];
    for (let j = 0; j < xData.length; j++) {
      strikeRet.push(Math.max(0, (strike - xData[j]) / premium));
    }
    res.push({name: strike, returns: strikeRet, xData: xData});
  };
  return res
}


