import React from 'react';
import ContractsPortfolio from './ContractsPortfolio.js';

const Stocks = ({ contractData, addContract, screenWidth, expiryChange, soloStrikeChange,strikesChange, priceChange, PositionChange, removeRow, stockChange, expirysChange, minPriceChange, maxPriceChange, addStock, contrChange, endDate, fetchTime, url, currentFetchType, stockList, intialPageLoad, greenColor }) => {
        //render ThirdRow for each item in contractData, passing in the contractData
        let stockRows = [];
        //make a list of lists, each list accounts for the contracts associated with a single stock
        let stockGroups = [];
        let parentIndices = [];
        let currentIndex = 0;
       // console.log(contractData)
        for (let i = 0; i < contractData.length; i++) {
            //add a new list to stockGroups with the current index as the first element
            stockGroups.push([contractData[i][0]])
            //add the current index to parentIndices
            parentIndices.push(i)
            // console.log("good 3")
            for (let j = 1; j < contractData[i].length; j++) {
                stockGroups[currentIndex].push(contractData[i][j])
            }
            currentIndex += 1
        }
       // console.log("good 5")
        //console.log(stockGroups)
        //for each key in the dictionary...
        
        for (let i = 0; i < stockGroups.length; i++) {
        //  if (contractData[i]["expired"] === false) {
                stockRows.push(
                    <ContractsPortfolio
                        key={i}
                        index={i}
                        contractData={stockGroups[i]}
                        addContract={addContract}
                        screenWidth={screenWidth}
                        expiryChange={expiryChange}
                        strikesChange={strikesChange}
                        priceChange={priceChange}
                        removeRow={removeRow}
                        parent={i}
                        stockChange={stockChange}
                        expirysChange={expirysChange}
                        minPriceChange={minPriceChange}
                        maxPriceChange={maxPriceChange}
                        soloStrikeChange={soloStrikeChange}
                        contrChange={contrChange}
                        PositionChange={PositionChange}
                        endDate={endDate}
                        fetchTime={fetchTime}
                        url={url}
                        currentFetchType={currentFetchType}
                        stockList={stockList}
                        intialPageLoad={intialPageLoad}
                        greenColor={greenColor}
                    />
                );
        // }
        }
        //now render the contractRows, followed by the add contract button, allow the button to call a function that adds an empty new contract to the contractData dictionary
        return (
            <>
                {stockRows}
                <div
                style={{
                    width: Math.min(800, screenWidth),
                    font: '12px Futura, sans-serif',
                    textAlign: 'center',
                    margin: "0 auto",
                    display: (screenWidth > 800) ? 'flex' : 'block',
                }}>
                <button
                    style={{
                        backgroundColor: greenColor,
                        color: 'black',
                        border: 'none',
                        width: '100px',
                        height: '23px',
                        display: (screenWidth > 800) ? 'flex' : 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        cursor: 'pointer',
                        marginTop: '6px',
                        marginBottom: '15px',
                        borderRadius: '3px',
                    }}
                    className="addContractButton"
                    onClick={() => addStock()}
                >
                Add Stock
                </button>
            </div>
            </>
        );
}

    

export default Stocks;