//I want to create a component that has 2 options, it looks like 2 rectangles next to each other, when I click the left rectangle it will be highlighted and the other rectangle greyed out, and vice versa for the other, it is a type of switch I guess
import React, { useState } from "react";

const MySwitch = ({option1, option2, onChange, chosenOption, greenColor}) => {
    const [activeOption, setActiveOption] = useState(chosenOption ? option1 : option2);

    const onItemClick = (option) => {
        setActiveOption(option);
        onChange(option);
    };

    return (
        <div
            style={{
                flex: '1',
                minWidth: '258px',
            }}
            >
        <div
            style={{
            backgroundColor: activeOption === option1 ? greenColor : '#161413',
            color: activeOption === option1 ? '#161413' : greenColor,
            width: '105px',
            marginLeft: '1px',
  //          width: '110px',
            marginTop: '2px',
            height: '15px',
            display: 'inline-block',
            cursor: 'pointer',
            border: '3px solid ' + greenColor,
            padding:'8px',
            }}
            onClick={() => onItemClick(option1)}
        >{option1}</div>
        <div
            style={{
            backgroundColor: activeOption === option2 ? greenColor : '#161413',
            color: activeOption === option2 ? '#161413' : greenColor,
            width: '105px',
            marginRight: '1px',
            height: '15px',
            marginTop: '2px',
            display: 'inline-block',
            cursor: 'pointer',
            border: '3px solid ' + greenColor,
            padding:'8px',
            }}
            onClick={() => onItemClick(option2)}
        >{option2}</div>
        </div>
    );
    }
      
export default MySwitch;