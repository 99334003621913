import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import CalcReturns from './returnCalcs.js';
import { CalcSellReturns, CalcEarlyReturns, UpdateAllReturns, CalcStrikeEarlyReturns, PortfolioReturns, CalcStrikeEarlyReturnsGraph } from './returnCalcs.js';
import MyChart from './MyChart.js';
import './index.css';
import UserGuide from './UserGuide.js';
import FirstRow from './firstRow.js';
import SecondRow from './SecondRow.js';
import Contracts from './Contracts.js';
import Stocks from './Stocks.js';
import Dropdown from './Dropdown.js';
import Collapsible from './Collapsible.js';
import { addDays, formatISO } from 'date-fns';

//I want to create a component that has 2 options, it looks like 2 rectangles next to each other, when I click the left rectangle it will be highlighted and the other rectangle greyed out, and vice versa for the other, it is a type of switch I guess

const Header = ({ text }) => {
  const splitText = text.split(' ');
  const firstHalf = splitText[0];
  const secondHalf = splitText[1];
  /*useEffect(() => {
    document.title = 'optionfinder';
  }, []);*/

  return (
    <h1>
      <span style={{ color: '#3EA055' }}>{firstHalf}</span>
      <span style={{ color: '#1E90FF' }}>{secondHalf}</span>
    </h1>
  );
}

/*
<DynamicWidth
dataChange={this.widthChange}
/> */
function DynamicWidth(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(Math.min(1000, (0.95 * window.innerWidth)));
      props.dataChange(Math.min(1000, (0.95 * window.innerWidth)));
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //rewrite above but after the width is changed, also call the function props.dataChange with the new width

  const divWidth = Math.min(400, windowWidth);

  return (
    <div
      style={{
        width: `${divWidth}px`,
        height: "40px",
        margin: "0 auto",
        textAlign: "center"
      }}
    >
      
    </div>
  );
}



//keep
//endDateAll
//holdTillExpiry
//width
//AllContractData
class All extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //set endDateAll to tomorrow's date
      endDateAll: addDays(new Date(), 1),
      endDate: addDays(new Date(), 1),
      expectedReturn: 0,
      expectedValue: 0,
      currentValue: 0,
      maxReturn: 0,
      minReturn: 0,
      graphReturns: Array(51).fill(0),
      graphPrices: Array(51).fill(0),
      lastUpdate: 0,
      holdTillExpiry: true,
      allStrikes: false,
      optionStrategy: "--Demo--",
      width: Math.min(1000, (0.95 * window.innerWidth)),
      currentFetchType: "initialStocks",
      stockList: [],
      AllContractData: [{"OG": true, stock: '--Underlying Ticker--',contr: "calls", side: "buying", contrType: "buy call", expiry: '--expiry--',futurePrice: "not set", returns: [], stockReturn: 0, expired: false, strikes: [], chosenStock: true, expirys: [], stockPrice: 0, premiums: []}],  //remove expired
      SelectContractData: [[{"OG": true, stock: '--Underlying Ticker--', strike: '--strike--',contr: "calls", contrType: "buy call", side: "buying", expiry: '--expiry--',futurePrice: "not set", returns: 0, stockReturn: 0, expired: false, position: "--Num of Shares--", strikes: [], chosenStock: true, minFuturePrice: "not set", maxFuturePrice:"not set", expirys: [], graphReturns: Array(51).fill(0), graphPrices: Array(51).fill(0), premiums: [] }]],
    };
   // this.state = this.initialState;
  }

  //map to update current contractData state variable, options: holdAllContractData, holdSelectContractData, earlyAllContractData, earlySelectContractData
  stateKeyMap = {
    true: "AllContractData",
    false:"SelectContractData",
    }

    optionStrategyMap = {
      "Bull call spread" : {optionCount: 2, expiries: ['2024-01-19', '2024-01-19'], contrTypes: ["buy call", "sell call"], strikes: [260, 320], positions: [100, 100], minPrice: 150, maxPrice: 400, futurePrice: 280, valueDate: "2024-01-15"},
      "Iron condor" : {optionCount: 4, expiries: ['2024-01-19', '2024-01-19', '2024-01-19', '2024-01-19'], contrTypes: ["buy call", "sell call", "buy put", "sell put"], strikes: [360, 320, 200, 240], positions: [100, 100, 100, 100], minPrice: 150, maxPrice: 400, futurePrice: 280, valueDate: "2024-01-17"},
      "Covered call" : { optionCount: 1, expiries: ['2024-01-19'], contrTypes: ["sell call"], strikes: [320], positions: [100], minPrice: 150, maxPrice: 400, futurePrice: 280, valueDate: "2024-01-15", longStock: true, stockPosition: 100},
      "Married put": { optionCount: 1, expiries: ['2024-01-19'], contrTypes: ["buy put"], strikes: [220], positions: [100], minPrice: 150, maxPrice: 400, futurePrice: 280, valueDate: "2024-01-15", longStock: true, stockPosition: 100},
      "Bear put spread": {optionCount: 2, expiries: ['2024-01-19', '2024-01-19'], contrTypes: ["buy put", "sell put"], strikes: [190, 130], positions: [100, 100], minPrice: 50, maxPrice: 350, futurePrice: 180, valueDate: "2024-01-15"},
      "Protective collar": { optionCount: 2, expiries: ['2024-01-19', '2024-01-19'], contrTypes: ["sell call", "buy put"], strikes: [320, 190], positions: [100, 100], minPrice: 100, maxPrice: 400, futurePrice: 280, valueDate: "2024-01-15", longStock: true, stockPosition: 100},
      "--Default--": { optionCount: 0, },
     // "Moss" : { optionCount: 4, expiries: ['2025-01-17', '2025-01-17', '2025-12-19', '2025-12-19'], contrTypes: ["buy call", "buy call", "buy call", "buy call"], strikes: [190, 290, 220, 250], positions: [100, 100, 100, 100], minPrice: 150, maxPrice: 400, futurePrice: 280, valueDate: "2024-01-22", longStock: true, stockPosition: 355.2},

    }

    async autofillDropdowns(strategy) {
      await new Promise(resolve => {
        this.setState({
          endDate: new Date(),
          expectedReturn: 0,
          expectedValue: 0,
          currentValue: 0,
          maxReturn: 0,
          minReturn: 0,
          graphReturns: Array(51).fill(0),
          graphPrices: Array(51).fill(0),
          lastUpdate: 0,
          allStrikes: false,
          optionStrategy: strategy.value,
          currentFetchType: "none",
          SelectContractData: [[{"OG": true, stock: '--Underlying Ticker--', strike: '--strike--',contr: "calls", contrType: "buy call", side: "buying", expiry: '--expiry--',futurePrice: "not set", returns: 0, stockReturn: 0, expired: false, position: "--Num of Shares--", strikes: [], chosenStock: true, minFuturePrice: "not set", maxFuturePrice:"not set", expirys: [], graphReturns: Array(51).fill(0), graphPrices: Array(51).fill(0), premiums: [] }]],
      }, resolve); // The second parameter to setState is a callback that is called after the state update is complete
      });

      //if the strategy is "--Default--" then we exit
      if (strategy.value === "--Default--") {
        return;
      }

      // Select the stock to be TSLA, thsi triggers an api call to get the expiries for TSLA
      await this.stockChange({value: 'TSLA', label: 'TSLA'}, 0);
      this.setState({ currentFetchType: "tickers" });
    
      // Wait until the api call has returned data or 2 seconds have passed.
      await new Promise(resolve => {
        const checkApiData = setInterval(() => {
          if (this.state.SelectContractData[0][0]["expirys"].length > 0) {
            clearInterval(checkApiData);
            resolve();
          }
        }, 100); // Check every 200ms
    
        setTimeout(() => {
          clearInterval(checkApiData);
          resolve();
        }, 2000); // Timeout after 2 seconds
      });
      this.setState({ currentFetchType: "none" });

      // Then you fill in all the options. Here is where strategy comes into play
      let optionData = this.optionStrategyMap[strategy.value];

      for (let i = 0; i < optionData.optionCount; i++) {
        if (i > 0) {
          this.addContract(0);
        }
        this.contrChange({ value: optionData.contrTypes[i]},0,i);
        this.expiryChange({value: optionData.expiries[i]},0,i);
      }
      
      if (optionData.longStock) {
        this.addContract(0);
        //find length of contracts and edit position of last one
        this.contrChange({ value: 'long stock'},0,optionData.optionCount);
        this.PositionChange(optionData.stockPosition,0,optionData.optionCount);
      }

      this.setState({ currentFetchType: "expiries" });

      //define func to check this.state.SelectContractData[0][i]["apiData"]["calls"]["close"].length > 0 where i < optionData.optionCount
      function checkApiCallsFinished(contractData) {
        for (let i = 0; i < optionData.optionCount; i++) {
          if (contractData[0][i]["apiData"]["calls"]["close"].length == 0) {
            return false;
          }
        }
        return true;
      }

      await new Promise(resolve => {
        const checkApiData2 = setInterval(() => {
          // check this.state.SelectContractData[0][i]["apiData"]["calls"]["close"].length > 0 where i < optionData.optionCount
          if (checkApiCallsFinished(this.state.SelectContractData)) {
            clearInterval(checkApiData2);
            resolve();
          }
        }, 100); // Check every 200ms
    
        setTimeout(() => {
          clearInterval(checkApiData2);
          resolve();
        }, 2000); // Timeout after 2 seconds
      });
      this.setState({ currentFetchType: "none" });

      for (let i = 0; i < optionData.optionCount; i++) {
        this.soloStrikeChange({value: optionData.strikes[i]},0,i);
        this.PositionChange(optionData.positions[i],0,i);
      }

      /*if (optionData.longStock) {
        this.addContract(0);
        //find length of contracts and edit position of last one
        this.contrChange({ value: 'long stock'},0,optionData.optionCount);
        this.PositionChange(optionData.stockPosition,0,optionData.optionCount);
      }*/
      //wait 250 ms before continuing
      await new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 250); // Timeout after 2 seconds
      });

      this.priceChange(optionData.futurePrice ,0);
      this.minPriceChange(optionData.minPrice ,0);
      this.maxPriceChange(optionData.maxPrice,0);
      this.handleEndDateChange(new Date(optionData.valueDate));

    }

  priceChange = (px, parent) => {
    let contractData = [...this.state.SelectContractData];
    let contracts = contractData[parent];
    // if this is variable stock, we make a chart

    contracts = CalcStrikeEarlyReturns(contracts, parseFloat(px), this.state.endDate);
    for (let i = 0; i < contracts.length; i++) {
      contracts[i]["futurePrice"] = px;
    }

    contractData[parent] = contracts;
    let returns = PortfolioReturns(contractData);
    let totalReturn = returns[0];
    let expectedVal = returns[3];
    let currentVal = returns[4];
    //if returns[5] are not all nulls
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: totalReturn,
      expectedValue: expectedVal,
      currentValue: currentVal,
     });
  }

  priceChangeAll = (px, index) => {
    let contractData = [...this.state.AllContractData];
    let contract = contractData[index];
    //if expiry is not set, then exit the function
    if (contract["expiry"] == "--expiry--") {
     // console.log("expiry not set")  // add an error pop-up later, perhaps conditional rendering, like the gpt example in "Bivariate Normal Distribution"
      return;
    }

    contract["futurePrice"] = px;
    let returnsData = [];

    if ((contract["side"] == "buying") && (this.state.holdTillExpiry)) {
      //console.log("CalcReturns")
      returnsData = CalcReturns(contract["apiData"][contract["contr"]]['strikes'], contract["apiData"][contract["contr"]]['close'], px, contract["contr"])
    } else if ((contract["side"] == "selling") && (this.state.holdTillExpiry)) {
     // console.log("CalcSellReturns")
      returnsData = CalcSellReturns(contract["apiData"][contract["contr"]]['close'], contract["apiData"][contract["contr"]]['strikes'], contract["stockPrice"], contract["expiry"])
    } else if ((contract["side"] == "buying") && (!this.state.holdTillExpiry)) {
    //  console.log("CalcEarlyReturns")
      returnsData = CalcEarlyReturns(contract["apiData"], px, contract["expiry"], contract["contr"], this.state.endDateAll)
    }

    let stockReturn = 0;
   // if (index == 0) {
      stockReturn = (px) / (contract["stockPrice"]);
  //  }

    contract["stockReturn"] = stockReturn;
    contract["returns"] = returnsData;

    contractData[index] = contract;
    this.setState({ AllContractData: contractData });
  }

  maxPriceChange = (px, index) => {
    let contractData = [...this.state.SelectContractData];
    let contracts = contractData[index];
    // if this is variable stock, we make a chart

   // contracts = CalcStrikeEarlyReturns(contracts, px, "maxReturn", this.state.endDate);
    //check first if max and min price are defined
    for (let i = 0; i < contracts.length; i++) {
      contracts[i]["maxFuturePrice"] = px;
    }

    //check minFuturePrice and maxFuturePrice are both numbers
    if ((px) && (contracts[0]["minFuturePrice"] != "not set")) {
      //console.log("both defined")
      contracts = CalcStrikeEarlyReturnsGraph(contracts, this.state.endDate);
    }
   // console.log("contracts", contracts)
    contractData[index] = contracts;
    let returns = PortfolioReturns(contractData);
    let totalReturn = returns[1];

    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      maxReturn: totalReturn,
    });
  }

  minPriceChange = (px, index) => {
    let contractData = [...this.state.SelectContractData];
    let contracts = contractData[index];
    // if this is variable stock, we make a chart

    for (let i = 0; i < contracts.length; i++) {
      contracts[i]["minFuturePrice"] = px;
    }

    //contracts = CalcStrikeEarlyReturns(contracts, px, "minReturn", this.state.endDate);
    if ((px) && (contracts[0]["maxFuturePrice"] != "not set")) {
      contracts = CalcStrikeEarlyReturnsGraph(contracts, this.state.endDate);
    }

    contractData[index] = contracts;
    let returns = PortfolioReturns(contractData);
    let totalReturn = returns[2];

   if (!returns[5].every((val) => val === null)) {
    this.setState({graphReturns: returns[5]});
  }
  if (!returns[6].every((val) => val === null)) {
    this.setState({graphPrices: returns[6]});
  }
  
      this.setState({
        SelectContractData: contractData,
        minReturn: totalReturn,
      });
  }

  contrChange = (contr, parent, index) => {
    let contractData = [...this.state.SelectContractData];
    let contrMap = {"long stock": "stock", "short stock": "stock", "buy call": "calls", "buy put": "puts", "sell call": "calls", "sell put": "puts"};
    let sideMap = {"long stock": "buying", "short stock": "selling", "buy call": "buying", "buy put": "buying", "sell call": "selling", "sell put": "selling"};
    let contract = contrMap[contr.value];
    let side = sideMap[contr.value];

    contractData[parent][index]["contr"] = contract;
    contractData[parent][index]["side"] = side;
    contractData[parent][index]["contrType"] = contr.value;

    contractData[parent][index]["strike"] = '--strike--';
    contractData[parent][index]["position"] = "--Num of Shares--";
    contractData[parent][index]["expiry"] = '--expiry--';
    contractData[parent][index]["strikes"] = [];
    contractData[parent][index]["premiums"] = [];
    contractData[parent][index]["graphReturns"] = Array(51).fill(0);
    contractData[parent][index]["graphPrices"] = Array(51).fill(0);
    contractData[parent][index]["returns"] = 0;

    let contracts = contractData[parent];
    contracts = UpdateAllReturns(contracts, this.state.endDate);
    contractData[parent] = contracts;
    let returns = PortfolioReturns(contractData);
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: returns[0],
      minReturn: returns[2],
      maxReturn: returns[1],
      expectedValue: returns[3],
      currentValue: returns[4],
    });
    this.setState({
      SelectContractData: contractData,
    });
  }

  contrChangeAll = (contr) => {
    let contractData = [...this.state.AllContractData];
    let contrMap = {"long stock": "stock", "short stock": "stock", "buy call": "calls", "buy put": "puts", "sell call": "calls", "sell put": "puts"};
    let sideMap = {"long stock": "buying", "short stock": "selling", "buy call": "buying", "buy put": "buying", "sell call": "selling", "sell put": "selling"};
    let contract = contrMap[contr.value];
    let side = sideMap[contr.value];
    contractData[0]["stockReturn"] = 0;

    for (let i = 0; i < contractData.length; i++) {
      contractData[i]["contrType"] = contr.value;
      contractData[i]["side"] = side;
      contractData[i]["contr"] = contract;
      contractData[i]["futurePrice"] = "not set";
      contractData[i]["returns"] = [];
    }
    this.setState({
      AllContractData: contractData,
    });
  }

  //user selects different stock
  stockChange = (stock, parent) => {
    //console.log(stock)
    let contractData = [...this.state["SelectContractData"]];
    let oldChosenStock = contractData[parent][0]["chosenStock"];
    let newContract = [{"OG": true, stock: '--Underlying Ticker--', strike: '--strike--',contr: "calls", contrType: "buy calls", side: "buying", expiry: '--expiry--', futurePrice: "not set", returns: 0, stockReturn: 0, expired: false, position: "--Num of Shares--", strikes: [], chosenStock: oldChosenStock, minFuturePrice: "not set", maxFuturePrice:"not set", expirys: [], graphReturns: Array(51).fill(0), graphPrices: Array(51).fill(0), premiums: []}];
    newContract[0]["stock"] = stock.value;
    newContract[0]["apiData"] = { calls: { strikes: [], close: []}, puts: { strikes: [], close: []}};
    newContract[0]["expiry"] = "--expiry--";
    contractData[parent] = newContract;

    return new Promise(resolve => {
      this.setState({
        SelectContractData: contractData,
      }, resolve); // The second parameter to setState is a callback that is called after the state update is complete
    });
  }

  intialPageLoad = (stocks) => {
    this.setState({
      stockList: stocks["stocks"],
      currentFetchType: "none",
    });


  }

  stockChangeAll = (stock) => {
    let contractData = [...this.state.AllContractData];
    //for each dictionary in contractData, change stock
    for (let i = 0; i < contractData.length; i++) {
      contractData[i]["stock"] = stock.value;
      contractData[i]["apiData"] = { calls: { strikes: [], close: []}, puts: { strikes: [], close: []}};
      contractData[i]["expiry"] = "--expiry--";
    }

    this.setState({
      AllContractData: contractData,
    });
  }

  //why did I sperate func above and func below, because of fetching? fetch needs to be triggered but cannot be in onChange, because it has to be within the React component in useEffect, so it has to happen during the resulting re-rendering of the component which then passes output to below func, maybe onChange/stockChange should be merged into below func?
  //when new stock is selected, the expirys need to be updated
  expirysChange = (newData, parent) => {
    let contractData = [...this.state.SelectContractData];
    let contracts = contractData[parent];

    const expirys = newData.expirys.map(expiry => {
      const label = expiry;
      return { value: label, label };
    });

    for (let i = 0; i < contracts.length; i++) {
      contracts[i]["expirys"] = expirys;
      contracts[i]["stockPrice"] = newData.stockPrice;
    }

    contracts = UpdateAllReturns(contracts, this.state.endDate);
    contractData[parent] = contracts;
    let returns = PortfolioReturns(contractData);
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: returns[0],
      minReturn: returns[2],
      maxReturn: returns[1],
      expectedValue: returns[3],
      currentValue: returns[4],
      currentFetchType: "none",
    });
  }

  expirysChangeAll = (newData) => {
    let contractData = [...this.state.AllContractData];
    const expirys = newData.expirys.map(expiry => {
      const label = expiry;
      return { value: label, label };
    });
    //for each dictionary in contractData, change expirys
    for (let i = 0; i < contractData.length; i++) {
      contractData[i]["expirys"] = expirys;
      contractData[i]["stockPrice"] = newData.stockPrice;
    }
    
    this.setState({ AllContractData: contractData, });
  }

  //user selects different expiry
  expiryChange = (expiry,parent,index) => {
    let contractData = [...this.state.SelectContractData];
    contractData[parent][index]["expiry"] = expiry.value;

    this.setState({ SelectContractData: contractData });
  }

  expiryChangeAll = (expiry, index) => {
    let contractData = [...this.state.AllContractData];
    //if stock is not set, exit early
    //if (contractData[0]["stock"] == "--stock--") {
    //  console.log("stock not set");
    //  return;
   // }
    contractData[index]["expiry"] = expiry.value;

    this.setState({ AllContractData: contractData });
  }

    //strikes and premiums now change when different expiry is selected
  strikesChange = (newData, parent, index) => {
    //console.log(index);
    //console.log(holding);
    let contractData = [...this.state.SelectContractData];
    let contract = contractData[parent][index];
    contract["apiData"] = newData;
    //if (contract["side"] == "selling") {
    //  returnsData = CalcSellReturns(newData[contract["contrType"]]["close"], newData[contract["contrType"]]['strikes'], newData["stockPrice"], contract["expiry"]);
    //} else {
    //  returnsData["returns"] = CalcReturns(newData[contract["contrType"]]["strikes"], newData[contract["contrType"]]["close"], contract["futurePrice"], contract["contrType"]);
    //}
    contract["returns"] = 0;
    contractData[parent][0]["stockReturn"] = contractData[parent][0]["futurePrice"] / newData["stockPrice"];
    contractData[parent][0]["stockPrice"] = newData["stockPrice"];
    contract["strikes"] = newData[contract["contr"]]["strikes"];
    contract["premiums"] = newData[contract["contr"]]["close"];
    contractData[parent][index] = contract;
    let lastUpdate = new Date(newData["lastUpdated"] / 1000000);
    lastUpdate = lastUpdate.toDateString() + " " + lastUpdate.toTimeString();

    contractData[parent][index]["strike"] = '--strike--';
    contractData[parent][index]["position"] = "--Num of Shares--";
    contractData[parent][index]["graphReturns"] = Array(51).fill(0);
    contractData[parent][index]["graphPrices"] = Array(51).fill(0);
    let contracts = contractData[parent];
    contracts = UpdateAllReturns(contracts, this.state.endDate);
    contractData[parent] = contracts;
    let returns = PortfolioReturns(contractData);
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: returns[0],
      minReturn: returns[2],
      maxReturn: returns[1],
      expectedValue: returns[3],
      currentValue: returns[4],
      lastUpdate: lastUpdate,
      currentFetchType: "none",
    });
  }

  strikesChangeAll = (newData, index) => {
    let contractData = [...this.state.AllContractData];
    let contract = contractData[index];
    //if stock is not set, exit early
   // if (contract["stock"] == "--stock--") {
   //   console.log("stock not set");
    //  return;
   // }
    contract["apiData"] = newData;
    contract["futurePrice"] = "not set";
    let returnsData = {returns: [], strikes: [], premiums: []};
    //do calcs
    contract["returns"] = returnsData["returns"];
    if (index == 0) {
      contract["stockReturn"] = 0;
    }
    contractData[0]["stockPrice"] = newData["stockPrice"];
    contract["strikes"] = newData[contract["contr"]]["strikes"];
    contract["premiums"] = newData[contract["contr"]]["close"];
    contractData[index] = contract;
    let lastUpdate = new Date(newData["lastUpdated"] / 1000000);
    lastUpdate = lastUpdate.toDateString() + " " + lastUpdate.toTimeString();

    this.setState({
         AllContractData: contractData,
         lastUpdate: lastUpdate,
      });
    }

  analysisChange = (analysis) => {
    let allStrikes = ("Compare Options" == analysis);
    //let holding;
   // if (!allStrikes) {
    //    holding: false,
    //}
    this.setState({
      allStrikes: allStrikes,
      minFuturePrice: "not set",
      maxFuturePrice: "not set",
      futurePrice: "not set",
    });
  }

  //function called when we switch from "holding till expiry" to "sell early" or vice-versa, NOTE: It is only called in the "AllStrikes" state
  sellEarlySwitch = (sellPt) => {
    let newValue;
    let endDate = this.state.endDateAll;
    if (sellPt == "hold till expiry") {
        newValue = true;
        endDate = new Date();
    } else {
        newValue = false;
    }

    this.setState(
        {
            holdTillExpiry: newValue,
            endDateAll: endDate,
        },
        () => {
            if (sellPt == "hold till expiry") {
                for (let i = 0; i < this.state.AllContractData.length; i++) {
                    this.priceChangeAll(this.state.AllContractData[i]["futurePrice"], i);
                }
            }
        }
    );
  };

  //function to log results of handleEndDateChange
  handleEndDateChange = (date) => {
    if (this.state.allStrikes) {
      this.setState(
        {
          endDateAll: date,
        },
        () => {
          for (let i = 0; i < this.state.AllContractData.length; i++) {
            if (this.state.AllContractData[i]["futurePrice"] != "not set") {
              this.priceChangeAll(this.state.AllContractData[i]["futurePrice"], i);
            }
          }
        }
        );
    } else {
      this.setState({endDate: date});
      let contractData = [...this.state.SelectContractData];
      for (let i = 0; i < contractData.length; i++) {
        let contracts = contractData[i];
        contracts = UpdateAllReturns(contracts, date);
        contractData[i] = contracts;
      }

      let returns = PortfolioReturns(contractData);
      if (!returns[5].every((val) => val === null)) {
        this.setState({graphReturns: returns[5]});
      }
      if (!returns[6].every((val) => val === null)) {
        this.setState({graphPrices: returns[6]});
      }

      this.setState({
        SelectContractData: contractData,
        expectedReturn: returns[0],
        minReturn: returns[2],
        maxReturn: returns[1],
        expectedValue: returns[3],
        currentValue: returns[4],
      });
    }
  };


//currentPrice, strikes, origStrikes, origPremiums, futurePrice

  soloStrikeChange = (strike, parent, index) => {
    let contractData = [...this.state.SelectContractData];
    let contracts = contractData[parent];

    contracts[index]["strike"] = strike.value;
    contracts[index]["position"] = "--Num of Shares--";
    contracts[index]["graphReturns"] = Array(51).fill(0);
    contracts[index]["graphPrices"] = Array(51).fill(0);
    contracts[index]["returns"] = 0;
    contracts = UpdateAllReturns(contracts, this.state.endDate);
    contractData[parent] = contracts;
    let returns = PortfolioReturns(contractData);
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: returns[0],
      minReturn: returns[2],
      maxReturn: returns[1],
      expectedValue: returns[3],
      currentValue: returns[4],
    });
  }

  PositionChange = (position, parent, index) => {
    let contractData = [...this.state.SelectContractData];
    let contracts = contractData[parent];
    contracts[index]["position"] = position;
    // if futurePrice is not a dictionary
    contracts = UpdateAllReturns(contracts, this.state.endDate);

    contractData[parent] = contracts;
    let returns = PortfolioReturns(contractData);
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: returns[0],
      minReturn: returns[2],
      maxReturn: returns[1],
      expectedValue: returns[3],
      currentValue: returns[4],
    });
  }

  widthChange = (width) => {
    this.setState({
      width: width,
    });
  }

  addContract = (parent) => {
    let contractData = [...this.state.SelectContractData];
    let newContract = {"OG": false, stock: '--Underlying Ticker--', strike: '--strike--',contr: "calls", contrType: "buy calls", side: "buying", expiry: '--expiry--',futurePrice: "not set",returns: 0, stockReturn: 0,expired: false,position: "--Num of Shares--",strikes: [], chosenStock: false, graphReturns: Array(51).fill(0), graphPrices: Array(51).fill(0)};
    newContract["stock"] = contractData[parent][0]["stock"];
    newContract["stockPrice"] = contractData[parent][0]["stockPrice"];
    newContract["expirys"] = contractData[parent][0]["expirys"];
    newContract["expiry"] = contractData[parent][0]["expiry"];
    newContract["strikes"] = contractData[parent][0]["strikes"];
    newContract["premiums"] = contractData[parent][0]["premiums"];
    newContract["apiData"] = contractData[parent][0]["apiData"];
    newContract["chosenStock"] = contractData[parent][0]["chosenStock"];
    //add a new empty dictionary to the contractData list
    //let contractNum = contractData.length;
    //console.log(newContract);
    contractData[parent].push(newContract);
    //console.log(contractData);

    this.setState({ SelectContractData: contractData });
  }

  addContractAll = () => {
    let contractData = [...this.state.AllContractData];
    let newContract = {"OG": false, stock: '--Underlying Ticker--',contr: "calls", contrType: "buy calls", side: "buying",expiry: '--expiry--',futurePrice: "not set", returns: [], stockReturn: 0, expired: false, strikes: [], chosenStock: false };
    newContract["stock"] = contractData[0]["stock"];
    newContract["stockPrice"] = contractData[0]["stockPrice"];
    newContract["expirys"] = contractData[0]["expirys"];
    newContract["expiry"] = contractData[0]["expiry"];
    newContract["strikes"] = contractData[0]["strikes"];
    newContract["premiums"] = contractData[0]["premiums"];
    newContract["apiData"] = contractData[0]["apiData"];
    contractData.push(newContract);
    this.setState({ AllContractData: contractData });
  };

  addStock = () => {
    let contractData = [...this.state.SelectContractData];
    let newContract = [{"OG": true, stock: '--Underlying Ticker--', strike: '--strike--',contr: "calls", contrType: "buy calls", side: "buying", expiry: '--expiry--', futurePrice: "not set", returns: 0, stockReturn: 0, expired: false, position: "--Num of Shares--", strikes: [], chosenStock: false, minFuturePrice: "not set", maxFuturePrice:"not set", expirys: [], graphReturns: Array(51).fill(0), graphPrices: Array(51).fill(0), premiums: []}];
    contractData.push(newContract);
    this.setState({ SelectContractData: contractData });
  };

  removeRow = (parent, index) => {
    let contractData = [...this.state.SelectContractData];
    // if the contract is the only one in the list, dont do anything
    if ((contractData.length < 2) && ((contractData[0].length < 2) || (index == -1))) {
      return;
    }
    if (index == -1) {
      contractData.splice(parent, 1);
    } else if (contractData[parent].length < 2) {
      contractData.splice(parent, 1);
    } else {
      contractData[parent].splice(index, 1);
    }
    //loop through first contract seeting chosenStock to true
    for (let i = 0; i < contractData[0].length; i++) {
      contractData[0][i]["chosenStock"] = true;
    }

    //if parent index is still in contractData, update returns
    if (contractData[parent]) {
      let contracts = contractData[parent];
      contracts = UpdateAllReturns(contracts, this.state.endDate);
      contractData[parent] = contracts;
    }
    let returns = PortfolioReturns(contractData);
    if (!returns[5].every((val) => val === null)) {
      this.setState({graphReturns: returns[5]});
    }
    if (!returns[6].every((val) => val === null)) {
      this.setState({graphPrices: returns[6]});
    }

    this.setState({
      SelectContractData: contractData,
      expectedReturn: returns[0],
      minReturn: returns[2],
      maxReturn: returns[1],
      expectedValue: returns[3],
      currentValue: returns[4],
    });
  }

  removeRowAll = (index) => {
    let contractData = [...this.state.AllContractData];
    // if the contract is the only one in the list, dont do anything
    if (contractData.length < 2) {
      return;
    }
    contractData.splice(index, 1);
    this.setState({ AllContractData: contractData });
  }

  demoChange = async (strategy) => {
    await this.autofillDropdowns(strategy);
    //console.log(this.state.optionStrategy);
  }

  render() {
    const url = 'https://options-alpha-finder.com/stocks/';
    const greenColor = '#3EA055	'; //'#43CD80';
    let optionStrategies = [{ value: "--Default--", label : "--Default--" }, {value: "Bull call spread" , label: "Bull call spread" }, {value: "Iron condor" , label: "Iron condor" }, { value: "Covered call", label: "Covered call" }, { value: "Married put", label: "Married put" }, { value: "Bear put spread", label: "Bear put spread"}, { value: "Protective collar", label: "Protective collar"}]; //, { value: "Moss", label: "Moss" }];
    //let retTypes = [{ value: "multiple" , label: "multiple" }, { value: "percent" , label: "percent" },];
   // let buySell = [{ value: "buying" , label: "buying" }, { value: "selling" , label: "selling" },];
   // let analysis = [{ value: "option-chain", label: "option-chain" }, { value: "single-strikes" , label: "single-strikes" },];
   // let soloStrikes = this.state.apiData[this.state.contrType]['strikes'].map((strike) => ({ value: strike, label: strike }));
    const stateKey = this.stateKeyMap[this.state.allStrikes];
    let contractData = this.state[stateKey];
    let stockPx = contractData[0]["stockPrice"];
    //make a list of expiries from AllContractData
    let expiries = [];
    for (let i = 0; i < this.state.AllContractData.length; i++) {
      let expiry = this.state.AllContractData[i]["expiry"];
      // if expiry is not equal to "--expiry--" or already in the list, skip
      if (expiry == "--expiry--" || expiries.includes(expiry)) {
        continue;
      }
      expiries.push(expiry);
    }

    //make a list of expiries from SelectContractData
    let selectExpiries = [];
    for (let i = 0; i < this.state.SelectContractData.length; i++) {
      for (let j = 0; j < this.state.SelectContractData[i].length; j++) {
        let expiry = this.state.SelectContractData[i][j]["expiry"];
        // if expiry is not equal to "--expiry--" or already in the list, skip
        if (expiry == "--expiry--" || selectExpiries.includes(expiry)) {
          continue;
        }
        selectExpiries.push(expiry);
      }
    }

    let granularReturns = [];
    for (let i = 0; i < contractData.length; i++) {
      for (let j = 0; j < contractData[i].length; j++) {
        //pull "returns" from contractData[i][j] and display number after contractData[i][0]["stock"]
        let returns = NaN;
        if (contractData[i][j]["side"] != "selling") {
          returns = ((contractData[i][j]["returns"] - 1) * 100).toFixed(2);
        }
        granularReturns.push(
          <div className="stockPrice">
            <h3>{contractData[i][0]["stock"]} Position {j + 1} | {returns} %</h3>
          </div>
        );
          //console.log(contractData[i][j]["returns"]);
        }
      }

  if (this.state.allStrikes) {
    return (
        <>
        <div
          style={{
            textAlign: (this.state.width > 800) ? null : 'center',
            margin: (this.state.width > 800) ? '20px' : null,
            paddingBottom: '50px',
            font: '18px "Century Gothic", Futura, sans-serif',
          }}
        >
          <Header text="option finder" />
        </div>
        <DynamicWidth
          dataChange={this.widthChange}
        />
        <FirstRow
          screenWidth={this.state.width}
          analysisChange={this.analysisChange}
          handleDateChange={this.handleEndDateChange}
          allStrikes={this.state.allStrikes}
          chosenExpiries={expiries}
          greenColor={greenColor}
        />
        <SecondRow
          holding={this.state.holdTillExpiry}
          contractData={contractData}
          screenWidth={this.state.width}
          minPriceChange={this.minPriceChange}
          maxPriceChange={this.maxPriceChange}
          contrChange={this.contrChangeAll}
          expiryChange={this.expiryChangeAll}
          strikesChange={this.strikesChangeAll}
          stockChangeAll={this.stockChangeAll}
          expirysChange={this.expirysChangeAll}
          sellEarlySwitch={this.sellEarlySwitch}
          handleDateChange={this.handleEndDateChange}
          chosenExpiries={expiries}
          url={url}
          currentFetchType={this.state.currentFetchType}
          displayDate={this.state.endDateAll}
          stockList={this.state.stockList}
          greenColor={greenColor}
        />
        <Contracts
          contractData={contractData}
          addContract={this.addContractAll}
          screenWidth={this.state.width}
          expiryChange={this.expiryChangeAll}
          strikesChange={this.strikesChangeAll}
          priceChange={this.priceChangeAll}
          removeRow={this.removeRowAll}
          endDate={this.state.endDateAll}
          url={url}
          currentFetchType={this.state.currentFetchType}
          holdTillExpiry={this.state.holdTillExpiry}
          greenColor={greenColor}
          />
        <div className="stockPriceContainer">
          <div className="stockPrice">
            <h3>Current Stock Price | {stockPx}</h3>
          </div>
        </div>
        <div 
          style={{
            width: this.state.width,
            font: '12px Futura, sans-serif',
            textAlign: 'center',
            margin: "0 auto",
            display: 'flex',
            color: '#23238E',
          }}
          >
          <MyChart
            holding={this.state.holdTillExpiry}
            allStrikes={this.state.allStrikes}
            width={this.state.width}
            contractData={contractData}
            graphReturns={this.state.graphReturns}
            graphPrices={this.state.graphPrices}
            currentValue={this.state.currentValue}
          />
        </div>
        <div className="lastUpdate">
          <h5>Premiums Last Updated on {this.state.lastUpdate}</h5>
        </div>
        <UserGuide
          width={this.state.width}
        />
        <div className='footer'></div>
        </>
      );

  } else {
    return (
      <>
      <div
        style={{
          textAlign: (this.state.width > 800) ? null : 'center',
          margin: (this.state.width > 800) ? '20px' : null,
          paddingBottom: '40px',
          font: '18px "Century Gothic", Futura, sans-serif',
        }}
      >
        <Header text="option finder" />
      </div>
      <DynamicWidth
        dataChange={this.widthChange}
      />
      <div 
        style={{
          width: Math.min(800, this.state.width),
          font: '12px Futura, sans-serif',
          textAlign: 'center',
          margin: "0 auto",
          display: (this.state.width > 800) ? 'flex' : 'block',
          color: '#7EFE00',
          marginBottom: '80px',
          //paddingBottom: '40px',
        }}
        >
        <Dropdown
          placeHolder="--Demo--"
          options={optionStrategies}
          onChange={this.demoChange}
          initialValue={{ value: this.state.optionStrategy, label: this.state.optionStrategy}}
          index={0}
        />
      </div>
      <FirstRow
        screenWidth={this.state.width}
        analysisChange={this.analysisChange}
        handleDateChange={this.handleEndDateChange}
        allStrikes={this.state.allStrikes}
        chosenExpiries={selectExpiries}
        displayDate={this.state.endDate}
        greenColor={greenColor}
      />
      <Stocks
          contractData={contractData}
          addContract={this.addContract}
          screenWidth={this.state.width}
          expiryChange={this.expiryChange}
          strikesChange={this.strikesChange}
          priceChange={this.priceChange}
          removeRow={this.removeRow}
          stockChange={this.stockChange}
          expirysChange={this.expirysChange}
          minPriceChange={this.minPriceChange}
          maxPriceChange={this.maxPriceChange}
          addStock={this.addStock}
          contrChange={this.contrChange}
          soloStrikeChange={this.soloStrikeChange}
          PositionChange={this.PositionChange}
          endDate={this.state.endDate}
          url={url}
          currentFetchType={this.state.currentFetchType}
          stockList={this.state.stockList}
          intialPageLoad={this.intialPageLoad}
          greenColor={greenColor}
          />
      <div className="stockPriceContainer">
        <div className="stockPrice">
            <h3>Current Value | {this.state.currentValue.toFixed(2)}</h3>
        </div>
        <div className="stockPrice">
            <h3>Expected Value | {this.state.expectedValue.toFixed(2)}</h3>
        </div>
        <div className="stockPrice">
            <h3>Expected Return | {this.state.expectedReturn.toFixed(2)}%</h3>
        </div>
        <div className="stockPrice">
            <h3>Max Return | {this.state.maxReturn.toFixed(2)}%</h3>
        </div>
        <div className="stockPrice">
            <h3>Min Return | {this.state.minReturn.toFixed(2)}%</h3>
        </div>
        <Collapsible
            title={"Granular Expected Returns"}
            content={granularReturns}
        />
      </div>
      <div 
        style={{
          width: this.state.width,
          font: '12px Futura, sans-serif',
          textAlign: 'center',
          margin: "0 auto",
          display: 'flex',
          color: '#23238E',
        }}
        >
        <MyChart
          holding={this.state.holdTillExpiry}
          allStrikes={this.state.allStrikes}
          width={this.state.width}
          contractData={contractData}
          graphReturns={this.state.graphReturns}
          graphPrices={this.state.graphPrices}
          currentValue={this.state.currentValue}
        />
      </div>
      <div className="lastUpdate">
        <h5>Premiums Last Updated on {this.state.lastUpdate}</h5>
      </div>
      <UserGuide
        width={this.state.width}
      />
      <div className='footer'></div>
      </>
    );
    }
  }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<All />);

//<div className="stockPrice">
//<h3>Current Stock Price {stockPx}</h3>
//</div>


