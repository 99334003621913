import React from 'react';
import ThirdRowPortfolio from './ThirdRowPortfolio.js';
import DropdownApiCall from './DropdownApiCall.js';
import NumberInput from './NumberInput.js';
import MinNumberInput from './MinNumberInput.js';
import MaxNumberInput from './MaxNumberInput.js';

const ContractsPortfolio = ({ contractData, addContract, screenWidth, soloStrikeChange,expiryChange, strikesChange, PositionChange, priceChange, removeRow, contrChange, stockChange, expirysChange, minPriceChange, maxPriceChange, parent, endDate, fetchTime, url, currentFetchType, stockList, intialPageLoad, greenColor }) => {

        //render ThirdRow for each item in contractData, passing in the contractData
        //get the length of the dictionary "contractData"
        let contractRows = [];
    // let parent = 0;
        let parentData = contractData[0]
        let minFuturePrice = parentData["minFuturePrice"]
        let maxFuturePrice = parentData["maxFuturePrice"]
        let stocks = stockList.map((tick) => ({ value: tick, label: tick }));
        let stock = parentData["stock"]
        let futurePrice = parentData["futurePrice"]
        let side = parentData["side"]
        let chosenStock = parentData["chosenStock"]
        //for each key in the dictionary...
        
        for (let i = 0; i < contractData.length; i++) {
        //  if (contractData[i]["expired"] === false) {
                contractRows.push(
                    <ThirdRowPortfolio
                        key={i}
                        index={i}
                        parent={parent}
                        contract={contractData[i]}
                        screenWidth={screenWidth}
                        expiryChange={expiryChange}
                        strikesChange={strikesChange}
                        soloStrikeChange={soloStrikeChange}
                        priceChange={priceChange}
                        removeRow={removeRow}
                        contrChange={contrChange}
                        PositionChange={PositionChange}
                        endDate={endDate}
                        fetchTime={fetchTime}
                        url={url}
                        currentFetchType={currentFetchType}
                        greenColor={greenColor}
                    />
                );
        // }
        }

        return (
            <>
            <div 
                style={{
                width: Math.min(800, screenWidth),
                font: '12px Futura, sans-serif',
                textAlign: 'center',
                margin: "0 auto",
                display: (screenWidth > 800) ? 'flex' : 'block',
                color: '#23238E',
                }}
            >
                <DropdownApiCall
                isSearchable
                placeHolder="--Underlying Ticker--"
                options={stocks}
                onChange={stockChange}
                dataChange={expirysChange}
                url={url}
                queryParam={stock}
                initialValue={{ value: stock, label: stock}}
                parent={parent}
                typeOfDropdown={'tickers'}
                currentFetchType={currentFetchType}
                titleString={"Underlying Stock"}
                intialPageLoad={intialPageLoad}
                />
                <NumberInput
                onChange={priceChange}
                inputPrice={futurePrice}
                side={side}
                width={screenWidth}
                parent={parent}
                index={0}
                placeHolder={"Stock Price Target"}
                chosenStock={chosenStock}
                endDate={endDate}
                greenColor={greenColor}
                />
                <MinNumberInput
                onChange={minPriceChange}
                inputPrice={minFuturePrice}
                width={screenWidth}
                index={parent}
                endDate={endDate}
                greenColor={greenColor}
                />
                <MaxNumberInput
                onChange={maxPriceChange}
                inputPrice={maxFuturePrice}
                width={screenWidth}
                index={parent}
                endDate={endDate}
                greenColor={greenColor}
                />
                <div
                    style={{
                        width: (screenWidth > 800) ? '31px' : screenWidth,
                    }}
                    >
                <button
                style={{
                    backgroundColor: '#1E90FF',
                    color: 'black',
                    border: 'none',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    display: (screenWidth > 800) ? 'flex' : 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '18px',
                    cursor: 'pointer',
                    marginTop: '6px',
                    marginLeft: '5px',
                    marginBottom: '6px',
                }}
                onClick={() => removeRow(parent, -1)}
                >x
            </button>
            </div>
            </div>
            {contractRows}
            <div
                style={{
                    width: Math.min(800, screenWidth),
                    font: '12px Futura, sans-serif',
                    textAlign: 'center',
                    margin: "0 auto",
                    display: (screenWidth > 800) ? 'flex' : 'block',
                }}>
                <div className="empty-container183" />
                <button
                    style={{
                        backgroundColor: greenColor,
                        color: 'black',
                        border: 'none',
                        width: '100px',
                        height: '23px',
                        display: (screenWidth > 800) ? 'flex' : 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        cursor: 'pointer',
                        marginTop: '6px',
                        borderRadius: '3px',
                        marginBottom: '6px',
                    }}
                    className="addContractButton"
                    onClick={() => addContract(parent)}
                >
                Add Contract
                </button>
            </div>
            
            </>
        );
    };

    

export default ContractsPortfolio;
